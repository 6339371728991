.bottom-pagination {
    padding: 16px;

    .pagination-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-button {
        width: 45px;
        height: 45px;
        border: 1px solid $safety-orange;
        color: $safety-orange;
        display: inline-block;
        padding: 12px;
        margin-right: 16px;
        font-size: 18px;
        line-height: 20px;
        text-align: center;

        span {
            color: $safety-orange;
        }

        &.active {
            background: $safety-orange;
            pointer-events: none;

            span {
                color: $white;
            }
        }
    }

    .pagination-dots {
        font-size: 34px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 8px -28px -6px;
        color: $safety-orange;
    }

    .pagination-link {
        display: inline-block;
        width: 18px;
        height: 18px;
        border: none;
        padding: 0;
        vertical-align: middle;
        cursor: pointer;

        span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;

            &::before,
            &::after {
                content: "";
                position: absolute;
                background: $safety-orange;
                width: 10px;
                height: 2px;
                left: 2px;
            }
        }

        &.prev-page {
            margin-right: 12px;

            span {
                &::before {
                    top: 5px;
                    transform: rotate(-45deg);
                }

                &::after {
                    top: 11px;
                    transform: rotate(45deg);
                }
            }
        }

        &.next-page {
            span {
                &::before {
                    top: 11px;
                    transform: rotate(-45deg);
                }

                &::after {
                    top: 5px;
                    transform: rotate(45deg);
                }
            }
        }

        &.disabled {
            opacity: 0.35;
            pointer-events: none;
        }
    }

    @include mq($not-mobile-tablet) {
        padding: 20px;
    }
}
