// flags we use from the base cartridge
.flag-icon {
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    width: 20px;
    height: 15px;
}

.flag-icon-gb,
.flag-icon-en {
    background-image: url("../../fonts/flags//4x3/gb.svg");
}

.flag-icon-nl {
    background-image: url("../../fonts/flags//4x3/nl.svg");
}

.flag-icon-fr {
    background-image: url("../../fonts/flags//4x3/fr.svg");
}

.flag-icon-be {
    background-image: url("../../fonts/flags//4x3/be.svg");
}

.flag-icon-lu {
    background-image: url("../../fonts/flags//4x3/lu.svg");
}

.flag-icon-es {
    background-image: url("../../fonts/flags//4x3/es.svg");
}

.flag-icon-de {
    background-image: url("../../fonts/flags//4x3/de.svg");
}
