@use "sass:list";

.timetable-container {
    margin-bottom: 0;

    @include mq($mobile-max) {
        margin-bottom: 40px;
    }
}

.timetable-component {
    margin-top: 55px;

    @include mq($mobile-max) {
        margin-top: 0;
    }

    .alert-error {
        align-items: center;
        background-color: $pastel-grey;
        border-left: 3px solid $safety-orange;
        display: none;
        justify-content: space-between;
        padding: 20px;

        @include mq($tablet-max) {
            margin: 0 auto;
            max-width: 860px;
            width: 100%;
        }

        @include mq($mobile-max) {
            flex-direction: column;
        }
    }

    .alert-error__icon {
        align-self: flex-start;
        background-position: left;
        background-size: 1.5em;
        display: block;
        width: 50px;

        @include mq($mobile-max) {
            align-self: center;
            margin-bottom: 15px;
        }
    }

    .alert-error__msg {
        line-height: 28px;
        margin: 0;
        padding: 0;
        width: calc(100% - 50px);
    }

    &.desktop {
        @include mq($mobile-max) {
            display: none;
        }
    }

    &.mobile {
        display: none;

        @include mq($mobile-max) {
            display: block;
        }
    }

    .content-page .main-content .content-detail-body & {
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include mq($tablet-max) {
            max-width: 100%;
        }
    }
}

.timetable-block__wrap {
    background-color: $white;
    margin: 78px -235px 0;
    padding: 9px;

    h3 {
        @include h3;

        display: block;
        font-family: $heading-pro-treble-heavy;
        line-height: 52px;
        text-transform: uppercase;
        padding-left: 6px;
        margin-top: 6px;
        margin-bottom: 39px;

        @include mq($mobile-max) {
            @include h4;

            padding-left: 0;
            margin-bottom: 13px;
        }

        span {
            display: block;
            color: transparent;
            -webkit-text-stroke: 1px $black;
        }
    }

    @include mq($until-dt-med) {
        margin: 55px -160px 0;
    }

    @include mq($until-dt-min) {
        margin: 55px -60px 0;
    }

    @include mq($tablet-max) {
        margin: 35px 0 0;
    }

    @include mq($mobile-tablet-pt) {
        border: none;
        overflow: hidden;
        padding: 0;
    }
}

// Filters
.timetable-filters__col {
    // mobile landscape - desktop
    display: flex;
    flex-grow: 1;

    + .timetable-filters__col {
        flex-grow: 0;
    }
}

.timetable-filters__row {
    // mobile portrait
    padding: 5px;

    + .timetable-filters__row {
        background-color: $pastel-grey;
        border-bottom: 1px solid $grey-main;
        border-top: 1px solid $grey-main;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq($mobile-max) {
            border-bottom: none;
            border-top: none;
        }
    }
}

.timetable-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include mq($not-mobile) {
        margin-bottom: 12px;
    }

    .timetable-filters__row:first-child {
        @include mq($mobile-max) {
            margin-bottom: 8px;
        }
    }

    .timetable-filters__row {
        @include mq($mobile-max) {
            background-color: transparent;
            padding: 0;
        }
    }

    .timetable-filters__col:first-child {
        @include mq($not-mobile) {
            flex: 0 0 23.2%;
        }
    }

    .timetable-filters__col:last-child {
        @include mq($not-mobile) {
            flex: 0 0 75.5%;
            justify-content: space-between;
        }
    }

    .filter-search {
        align-items: center;
        border: 1px solid $grey-main-middle;
        display: flex;
        height: 56px;
        padding: 0 10px;
        position: relative;
        width: 100%;

        @include mq($not-mobile) {
            padding: 0 15px;
        }

        .search-input {
            border: 0;
            box-shadow: none;
            box-sizing: border-box;
            font-size: 16px;
            height: 43px;
            padding: 0;
            width: 100%;
            color: $black;

            &[disabled] + .search-clear {
                cursor: not-allowed;
                opacity: 0.5;
            }

            @include mq($tablet-max) {
                font-size: 14px;
                height: 28px;
            }
        }

        .search-clear {
            background-color: $grey-main;
            border-radius: 50%;
            display: block;
            height: 16px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;

            &::before,
            &::after {
                background-color: $white;
                border-radius: 2px;
                content: "";
                height: 10px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                width: 2px;
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        .suggestions-flyout {
            top: 44px;
            border: none;

            @include mq($tablet-max) {
                top: 40px;
            }
        }

        &::before {
            @include iconfont-styles;

            color: $black;
            content: svg(search);
            display: inline-block;
            font-size: 24px;
            position: relative;
            vertical-align: middle;
            width: 27px;

            @include mq($tablet-max) {
                font-size: 16px;
            }
        }

        &.disabled {
            &::before {
                opacity: 0.5;
            }
        }

        @include mq($mobile-tablet-pt) {
            height: 30px;
        }

        @include mq($mobile-max) {
            background-color: $white;
            border: 1px solid $grey-main;
            margin-bottom: 4px;
            height: 32px;
        }
    }

    .select-goal,
    .select-groupclasses,
    .select-status {
        border: none;
        box-shadow: none;
        cursor: pointer;
        font-size: 16px;
        height: 56px;
        padding: 0 35px 0 10px;

        @include mq($not-mobile) {
            padding-left: 12px;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }

        @include mq($mobile-tablet-pt) {
            font-size: 14px;
            height: 28px;
            padding: 0 25px 0 10px;
        }
    }

    .filter-select {
        border: 1px solid $grey-main-middle;
        height: 56px;
        position: relative;

        @include mq($not-mobile-tablet) {
            width: 120px;
        }

        @include mq($desktop-xxl) {
            width: 192px;
        }

        select {
            background-color: transparent;
            position: relative;
            z-index: 1;
            text-overflow: ellipsis;
        }

        &::after {
            @include iconfont-styles;

            color: $black;
            content: svg(chevron-down);
            display: inline-block;
            font-size: 14px;
            line-height: 45px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 13px;
            z-index: 0;

            @include mq($mobile-tablet-pt) {
                line-height: 30px;
            }

            @include mq($not-mobile-tablet) {
                right: 17px;
            }

            @include mq($mobile-max) {
                color: $black;
                font-size: 13px;
                font-weight: bold;
            }
        }

        &.disabled {
            &::after {
                opacity: 0.5;
            }
        }

        @include mq($mobile-tablet-pt) {
            height: 30px;
        }

        @include mq($mobile-max) {
            background-color: $white;
            border-color: $grey-main;
        }
    }

    .filter-goal {
        margin-left: 15px;

        @include mq($tablet-max) {
            display: none;
        }
    }

    .filter-groupclasses {
        margin-left: 15px;

        @include mq($mobile-max) {
            flex-basis: 100%;
            margin: 5px 0 0;
        }
    }

    .filter-status {
        display: flex;
        margin: 0 25px;

        @include mq($not-mobile) {
            margin: 0 8px;
        }

        &.filter-select {
            position: relative;

            .statuses {
                left: 10px;
                height: 45px;
                position: absolute;
                top: 0;

                @include mq($mobile-tablet-pt) {
                    height: 30px;
                }
            }

            .statuses-live,
            .statuses-virtual {
                border-radius: 50%;
                display: block;
                height: 8px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;

                @include mq($mobile-max) {
                    display: none;
                }
            }

            .statuses-live {
                background-color: $green;

                .show-virtual:not(.show-live) & {
                    // hide if only .show-virtual class applies
                    display: none;
                }
            }

            .statuses-virtual {
                background-color: $safety-orange;
                left: 5px;

                .show-live:not(.show-virtual) & {
                    // hide if only .show-live class applies
                    display: none;
                }

                .show-virtual:not(.show-live) & {
                    // re-align if only .show-virtual class applies
                    left: 0;
                }
            }

            .select-status {
                padding-left: 30px;

                @include mq($mobile-max) {
                    padding-left: 10px;
                }
            }
        }

        &.desktop {
            @include mq($mobile-tablet-pt) {
                display: none;
            }
        }

        &.mobile {
            display: none;

            @include mq($mobile-tablet-pt) {
                display: block;
            }
        }

        @include mq($mobile-tablet-pt) {
            margin: 0 15px 0 10px;
        }

        @include mq($mobile-max) {
            flex-basis: 48%;
            margin: 5px 0 0;
        }
    }

    .filter-status__virtual,
    .filter-status__live {
        align-items: center;
        display: flex;
        height: 56px;
        line-height: 45px;
        padding: 0 10px;
        position: relative;
        border: 1px solid $grey-main-middle;

        .checkbox {
            display: none;

            &:checked ~ label {
                &::after {
                    opacity: 1;
                    position: absolute;
                    top: 5px;
                    left: 11px;
                    border-radius: 50%;
                    content: "";
                    @include iconfont-styles;

                    content: svg(check);
                    color: $safety-orange;
                    cursor: pointer;
                    display: block;
                    height: 16px;
                    width: 16px;
                }
            }
        }

        label {
            align-items: center;
            cursor: pointer;
            display: flex;

            &::after {
                opacity: 0;
                transition: opacity 0.25s ease-in-out;
            }

            &::before {
                border: 2px solid $grey-main;
                content: "";
                cursor: pointer;
                display: block;
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }

        .form-check-label {
            span {
                color: $black;
                font-family: $heading-pro-treble-extra-bold;
                font-size: 16px;
                align-items: center;
                display: flex;

                &::before {
                    content: "";
                    display: block;
                    height: 19px;
                    margin-right: 10px;
                    width: 5px;
                }
            }
        }

        &.disabled {
            opacity: 0.5;

            label {
                cursor: not-allowed;

                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }

    .filter-status__virtual {
        border-right: none;

        span {
            &::before {
                background-color: $safety-orange;
            }
        }
    }

    .filter-status__live {
        span {
            &::before {
                background-color: $green;
            }
        }
    }

    .filter-date {
        display: flex;
        height: 56px;

        @include mq($mobile-max) {
            border: 1px solid $grey-main-middle;
            flex-basis: 100%;
            height: 32px;
        }

        &.disabled {
            opacity: 0.5;

            .filter-date__current {
                cursor: not-allowed;
            }
        }

        @include mq($mobile-tablet-pt) {
            box-shadow: none;
        }
    }

    .filter-date__button {
        background-color: $white;
        display: flex;
        height: 56px;
        justify-content: center;
        position: relative;
        width: 45px;

        &:hover {
            background-color: transparent;
            border: none;

            &::before {
                background: linear-gradient(to right, $safety-orange, $purple-1);
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;
            }

            &::after {
                background-color: $pastel-orange;
                bottom: 2px;
                content: "";
                left: 2px;
                position: absolute;
                right: 2px;
                top: 2px;
                z-index: 1;
            }

            span {
                position: relative;
                top: 1px;
                z-index: 2;
            }
        }

        span {
            &::before {
                display: block;
                line-height: 51px;
                font-size: 16px;

                @include mq($mobile-tablet-pt) {
                    line-height: 28px;
                }

                @include mq($mobile-max) {
                    color: $black;
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }

        &[disabled] {
            .icon-chevron-left,
            .icon-chevron-right {
                &::before {
                    opacity: 0.5;
                }
            }
        }

        @include mq($not-mobile) {
            border: 1px solid $grey-main-middle;
            width: 56px;
        }

        @include mq($mobile-tablet-pt) {
            height: 30px;
            width: 30px;
        }

        @include mq($mobile-max) {
            border: none;
            width: 30px;
        }

        &.next {
            @include mq($mobile-max) {
                border-left: 1px solid $grey-main-middle;
            }
        }

        &.prev {
            @include mq($mobile-max) {
                border-right: 1px solid $grey-main-middle;
            }
        }
    }

    .filter-date__current {
        background-color: $white;
        border-bottom: 1px solid $grey-main-middle;
        border-top: 1px solid $grey-main-middle;
        cursor: default;
        height: 56px;
        font-family: $heading-pro-treble-heavy;
        line-height: 56px;
        padding: 0 10px;
        position: relative;
        text-align: center;
        width: auto;

        @include mq($desktop-xxl) {
            width: 196px;
        }

        a {
            color: $black;
            font-size: 16px;
            text-transform: none;
        }

        @include mq($mobile-tablet-pt) {
            height: 30px;
            line-height: 30px;
        }

        @include mq($mobile-max) {
            border: none;
            flex-grow: 1;
            text-align: center;
        }
    }

    .modal-active.blurred & {
        position: relative;

        &::after {
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }

    @include mq($mobile-max) {
        flex-direction: column;
        margin: 0;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

// Time table
.timetable {
    position: relative;
    padding: 0 15px 15px;
    border: 1px solid $grey-main-middle;

    @include mq($mobile-max) {
        margin-top: 8px;
        padding: 0;
    }
}

.timetable-row {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid $grey-main-middle;

    @include mq($not-mobile) {
        padding: 33px 0 32px;
    }
}

.timetable-timeslots__wrap {
    flex-direction: column;
    height: 100%;
    height: 750px;
    overflow-x: hidden;
    overflow-y: auto;

    @include mq($not-mobile) {
        padding: 0;
    }

    .timetable-col__wrap {
        align-items: stretch;

        @include mq($mobile-max) {
            flex: 1 0 100%;
        }
    }

    @include mq($mobile-max) {
        background-color: $pastel-grey;
        display: flex;
        height: 300px;
    }
}

.timetable-col__container {
    align-self: stretch;
    display: flex;
    flex: 1 0 100%;
}

.timetable-col__wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% / 7);

    > p {
        color: $black;
        @include body;

        font-family: $heading-pro-treble-extra-bold;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        margin: auto 0;

        @include mq($mobile-tablet-pt) {
            font-size: 12px;
        }
    }

    &.current {
        > p {
            color: $safety-orange;
        }
    }

    &:nth-child(odd) {
        .timetable-col {
            background-color: rgba($pastel-grey, 0.5);
        }
    }

    @include mq($mobile-max) {
        width: 100%;
    }
}

.timetable-col {
    background-color: $white-smoke;
    border: 1px solid $grey-main-middle;
    flex-grow: 1;
    padding-top: 5px;
    width: 100%;

    @include mq($mobile-max) {
        padding: 0;
    }
}

.timetable-timeslot {
    background-color: $white;
    border-left: 3px solid $white;
    cursor: pointer;
    display: flex;
    line-height: 20px;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;

    .training-name {
        color: $black;
        display: block;
        font-family: $heading-pro-treble-extra-bold;
        font-size: 13px;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: uppercase;

        @include mq($tablet-ls) {
            font-size: 14px;
        }

        @include mq($mobile-tablet-pt) {
            font-size: 12px;
        }

        @include mq($mobile-max) {
            flex-grow: 1;
            font-size: 13px;
            line-height: 13px;
        }
    }

    .training-time {
        color: $black;
        @include body;

        line-height: 18px;
        display: block;
        white-space: nowrap;

        @include mq($tablet-ls) {
            font-size: 14px;
        }

        @include mq($mobile-tablet-pt) {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include mq($mobile-max) {
            flex-basis: 95px;
            flex-shrink: 0;
            font-size: 13px;
            line-height: 13px;
        }
    }

    &.live {
        border-left-color: $green;
        background-color: rgba($blue-4, 0.1);

        .show-live & {
            @include mq($mobile-max) {
                display: flex;
            }

            @include mq($not-mobile) {
                visibility: visible;
            }
        }
    }

    &.virtual {
        border-left-color: $safety-orange;
        background-color: rgba($safety-orange, 0.1);

        .show-virtual & {
            @include mq($mobile-max) {
                display: flex;
            }

            @include mq($not-mobile) {
                visibility: visible;
            }
        }
    }

    &.empty {
        background-color: transparent;
        border-left: none;
        cursor: default;
        margin: 0;
        padding: 0;

        @include mq($mobile-max) {
            display: none;
        }
    }

    &::after {
        @include mq($mobile-max) {
            @include iconfont-styles;

            color: $black;
            content: svg(chevron-right);
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 13px;
            font-weight: bold;
        }
    }

    @include mq($not-mobile) {
        visibility: hidden;

        &.is-hidden-groupclass {
            visibility: hidden !important;
        }
    }

    @include mq($mobile-tablet-pt) {
        line-height: 16px;
        padding: 10px;
    }

    @include mq($mobile-max) {
        display: none;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 5px;
        padding-right: 35px;
        position: relative;

        &.is-hidden-groupclass {
            display: none !important;
        }
    }
}

// Generate timeslot styles based on times map in _mixins.scss
// Deduces 5px from non-empty slots to keep calculations accurate while maintaining whitespace

@each $mins, $pixels in $times {
    .timeslot-#{$mins} {
        height: list.nth($pixels, 1);

        @if $mins < 30 {
            padding: 0 15px;
            justify-content: center;

            @include mq($mobile-tablet-pt) {
                line-height: 16px;
                padding: 0 10px;
            }
        }

        @include mq($mobile-tablet-pt) {
            height: list.nth($pixels, 2);
        }

        @include mq($mobile-max) {
            height: 50px;
        }

        &:not(.empty) {
            height: calc(#{list.nth($pixels, 1)} - 5);

            @if $mins <= 10 {
                height: 40px;
            }

            @include mq($mobile-tablet-pt) {
                height: calc(#{list.nth($pixels, 2)} - 5);
            }
        }
    }
}

// Timetable footer links
.timetable-footer {
    display: flex;
    height: 30px;
    justify-content: space-between;
    margin-top: 20px;

    a {
        align-items: center;
        display: flex;
        font-size: 14px;
        padding: 5px 0;

        span {
            margin-right: 5px;
        }
    }

    @include mq($mobile-tablet-pt) {
        display: none;
    }
}

// Modals
.modal-active {
    position: relative;

    .timetable-modal__wrap {
        display: block;
    }

    .timetable-col__container {
        position: relative;

        &::after {
            background-color: rgba($black, 0.5);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;

            @include mq($mobile-max) {
                display: none;
            }
        }
    }

    .timetable-timeslots__wrap {
        overflow-y: hidden;
    }

    &.blurred {
        .timetable-modal__wrap {
            background: rgba($black, 0.3);
            bottom: 0;
            top: 0;

            @include mq($mobile-tablet-pt) {
                height: 100vh;
                position: fixed;
                z-index: 100;
            }
        }

        .timetable-block {
            filter: blur(2px);
        }

        .timetable-col__container {
            position: static;

            &::after {
                display: none;
            }
        }

        .timetable-footer {
            position: relative;

            &::after {
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        @include mq($mobile-tablet-pt) {
            overflow: visible;
            position: static;
        }
    }

    &.no-results {
        .timetable-modal__wrap {
            @include mq($mobile-tablet-pt) {
                top: 128px;
            }
        }
    }
}

.timetable-modal__wrap {
    bottom: 65px;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 200px;
    width: 100%;
    z-index: 5;

    @include mq($mobile-max) {
        background-color: rgba($black, 0.5);
        bottom: 0;
        top: 90px;
    }
}

.timetable-modal {
    background: $white;
    position: absolute;
    text-align: center;
    padding: 80px 80px 38px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    @include mq($mobile-max) {
        padding: 22px 20px 10px 21px;
    }

    @include mq($not-mobile) {
        width: 75.5%;
    }

    @include mq($desktop-xxl) {
        width: 48.5%;
    }

    h4 {
        @include h4;

        margin: 0;
        text-align: left;

        @include mq($mobile-max) {
            @include h5;
        }

        span {
            display: block;
            color: transparent;
            -webkit-text-stroke: 1px $black;
        }
    }

    .search-wrapper {
        margin-top: 33px;

        @include mq($mobile-max) {
            margin-top: 0;
        }

        .search-input {
            @include body;

            background-color: transparent;
            box-sizing: border-box;
            height: 56px;
            margin-top: 15px;
            width: 100%;
            color: $nero;

            @include mq($mobile-max) {
                margin-top: 5px;
            }

            &::placeholder {
                color: $black;
            }
        }

        .asset-search-block {
            @include mq($mobile-max) {
                margin-bottom: 0;
            }

            .search-input {
                @include mq($mobile-max) {
                    border-bottom: none;
                }
            }

            .search-button::after {
                @include mq($mobile-max) {
                    margin-left: 15px;
                }
            }
        }
    }

    .retry-search {
        display: inline-block;
        margin-top: 25px;

        span {
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .suggestions-flyout {
        box-shadow: none;

        &.timetable {
            border: 1px solid $black;

            @include mq($mobile-max) {
                top: 40px;
            }
        }
    }
}

.timetable-modal__close {
    background-color: $white;
    height: 24px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 0;
    width: 24px;

    .icon-close {
        line-height: 24px;

        &::before {
            color: $black;
            font-size: 16px;

            @include mq($mobile-tablet-pt) {
                font-size: 24px;
            }

            @include mq($mobile-max) {
                font-size: 16px;
            }
        }
    }

    @include mq($mobile-tablet-pt) {
        right: 30px;
        top: 25px;
    }

    @include mq($mobile-max) {
        right: 15px;
        top: 15px;
    }
}

// Lesson detail modal
.timetable-lesson {
    display: flex;
    padding: 0;
    text-align: left;
    top: 50%;
    width: 80%;

    .lesson-col__left {
        flex-basis: 40%;
        padding: 40px 30px;

        h3,
        .outlined {
            font-size: 48px;
            line-height: 48px;
            font-family: $impact;
            margin: 0;
            text-transform: uppercase;

            @include mq($mobile-tablet-pt) {
                font-size: 32px;
                line-height: 36px;
                width: 100%;
            }
        }

        .outlined {
            color: $white;
            margin-bottom: 25px;

            &.virtual {
                -webkit-text-stroke: 1px $orange-2;

                @include mq($ie11) {
                    text-shadow: -1px -1px 0 $orange-2, 1px -1px 0 $orange-2, -1px 1px 0 $orange-2, 1px 1px 0 $orange-2;
                }
            }

            &.live {
                -webkit-text-stroke: 1px $green;

                @include mq($ie11) {
                    text-shadow: -1px -1px 0 $green, 1px -1px 0 $green, -1px 1px 0 $green, 1px 1px 0 $green;
                }
            }

            @include mq($mobile-tablet-pt) {
                margin-bottom: 15px;
            }
        }

        span[class^="icon-"] {
            display: block;
            margin-right: 5px;
            vertical-align: middle;
            width: 20px;

            &::before {
                color: $safety-orange;
            }
        }

        @include mq($mobile-tablet-pt) {
            display: flex;
            flex-basis: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: auto;
            padding: 0 15px;
        }

        @include mq($mobile-max) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .lesson-location,
    .lesson-upcoming,
    .lesson-schedule {
        display: flex;

        p {
            margin: 0;
        }
    }

    .lesson-location {
        margin-top: 15px;

        @include mq($mobile-tablet-pt) {
            margin-bottom: 15px;
        }
    }

    .lesson-upcoming,
    .lesson-schedule {
        margin-top: 25px;

        h4 {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin: 0;
            padding: 0;
        }

        @include mq($mobile-tablet-pt) {
            flex-basis: 50%;
        }

        @include mq($mobile-max) {
            flex-basis: auto;
        }
    }

    .lesson-text {
        flex-grow: 1;
    }

    .lesson-schedule__row {
        display: flex;
    }

    .lesson-schedule__col {
        display: block;
        flex-basis: 70px;

        + .lesson-schedule__col {
            flex-basis: calc(100% - 80px);
        }
    }

    .lesson-col__right {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        flex-basis: 60%;
    }

    .lesson-video {
        height: 0;
        overflow: hidden;
        padding-bottom: calc(360 / 640 * 100%);
        position: relative;
        width: 100%;

        .lesson-image {
            max-width: 100%;
            height: auto;
        }

        iframe {
            bottom: 0;
            height: 100%;
            right: 0;
            position: absolute;
            top: 0;
            vertical-align: bottom;
            width: 100%;
        }
    }

    .lesson-description {
        background-color: $pastel-grey;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        line-height: 28px;
        min-height: 120px;
        padding: 25px 50px;

        p {
            align-content: center;
            display: flex;
            justify-content: center;
            margin: 0;
        }

        .lesson-description__anchor {
            // mobile only
            display: flex;
            height: 40px;
            justify-content: center;
            line-height: 40px;
            position: relative;

            .icon {
                display: block;
                margin-left: 5px;
                transition: transform 0.25s ease-in-out;
            }

            .read-less {
                display: none;
            }

            @include mq($mobile-tablet-pt) {
                &::before {
                    background: linear-gradient(180deg, rgba($pastel-grey, 0) 0%, $pastel-grey 100%);
                    bottom: 40px;
                    content: "";
                    height: 75px;
                    left: 0;
                    opacity: 1;
                    position: absolute;
                    right: 0;
                    transition: opacity 0.25s ease-in-out;
                    width: 100%;
                }
            }
        }

        @include mq($mobile-tablet-pt) {
            line-height: 24px;
            padding: 15px 25px 0;
        }
    }

    .lesson-description__expandable {
        // mobile only
        height: 100%;
        max-height: 75px;
        overflow-y: hidden;
        transition: max-height 0.5s ease-in-out;

        &.expanded {
            max-height: 1000px;

            + .lesson-description__anchor {
                .icon {
                    transform: rotate(-180deg);
                }

                &::before {
                    opacity: 0;
                }

                .read-more {
                    display: none;
                }

                .read-less {
                    display: flex;
                }
            }
        }
    }

    .desktop {
        @include mq($mobile-tablet-pt) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @include mq($mobile-tablet-pt) {
            display: block;
            flex-basis: 100%;
        }

        @include mq($mobile-max) {
            flex-basis: auto;
        }
    }

    @include mq($mobile-tablet-pt) {
        height: calc(100% - 40px);
        margin: 30px auto 10px;
        overflow-y: auto;
        padding: 25px 0;
        width: 90%;
    }
}
