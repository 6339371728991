.teal {
    color: $spray;
}

.orange {
    color: $safety-orange;
}

.purple {
    color: $purple-heart;
}

.custom-background {
    position: relative;
    z-index: 0;
}

.background--gray {
    background-color: $white-smoke;
}

.background-grey {
    background-color: $wild-sand;
}

.background-white {
    background-color: $white;
}

.background-grey--top {
    &.background-white--bottom {
        background-image: linear-gradient(180deg, $wild-sand 0%, $wild-sand 50%, $white 50%, $white 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, $wild-sand 0%, $wild-sand 66%, $white 66%, $white 100%);
        }
    }

    &.background-transparent--bottom {
        background-image: linear-gradient(180deg, $wild-sand 0%, $wild-sand 50%, transparent 50%, transparent 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, $wild-sand 0%, $wild-sand 66%, transparent 66%, transparent 100%);
        }
    }
}

.background-white--top {
    &.background-grey--bottom {
        background-image: linear-gradient(180deg, $white 0%, $white 50%, $wild-sand 50%, $wild-sand 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, $white 0%, $white 66%, $wild-sand 66%, $wild-sand 100%);
        }
    }

    &.background-transparent--bottom {
        background-image: linear-gradient(180deg, $white 0%, $white 50%, transparent 50%, transparent 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, $white 0%, $white 66%, transparent 66%, transparent 100%);
        }
    }
}

.background-transparent--top {
    &.background-grey--bottom {
        background-image: linear-gradient(180deg, transparent 0%, transparent 50%, $wild-sand 50%, $wild-sand 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, transparent 0%, transparent 66%, $wild-sand 66%, $wild-sand 100%);
        }
    }

    &.background-white--bottom {
        background-image: linear-gradient(180deg, transparent 0%, transparent 50%, $white 50%, $white 100%);

        @include mq($not-mobile-tablet-pt) {
            background-image: linear-gradient(180deg, transparent 0%, transparent 66%, $white 66%, $white 100%);
        }
    }
}

.background--teal {
    background-color: $spray;
}

.background--orange {
    background: linear-gradient(310deg, $safety-orange 75%, $supernova 100%);
}

.background--black {
    background: radial-gradient(50% 50% at 50% 50%, #555 0%, $nero 100%);
}

.background--plain-black {
    background-color: $black;
}

.free {
    font-weight: bold;
}

// Hide content intended only for screen readers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

.text-uppercase {
    text-transform: uppercase;
}

.swiper__next-text {
    @include body;

    text-transform: uppercase;
    margin-right: 5px;

    @include mq($not-mobile-tablet) {
        display: none;
    }
}

.font-smaller {
    @include mq($desktop-xxl) {
        font-size: 2.65vw !important;
    }

    @include mq($not-mobile-tablet) {
        font-size: 3vw !important;
    }

    @include mq($mobile-pt-l) {
        font-size: 6vw !important;
    }
}

.d-none {
    display: none !important;
}

.flex-container {
    display: flex;

    &.flex-left {
        justify-content: flex-start;
    }

    &.flex-center {
        justify-content: center;
    }

    &.flex-right {
        justify-content: flex-end;
    }
}

.text-orange {
    color: $safety-orange;
}

.text-black {
    color: $nero;
}

.text-white {
    color: $white;
}

.text-white-outlined {
    -webkit-text-stroke: 1px $white;
    color: transparent;
}

.text-center {
    text-align: center;
}

.page-home .desktop-max-width {
    @include mq($desktop-uhd) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1320px;
        padding-left: 0;
        padding-right: 0;
    }
}
