.home-club {
    background-color: $white;
    max-width: $max-width-smaller;
    margin: 0 auto;

    @include mq($tablet-max) {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    section {
        @include mq($tablet-max) {
            padding: 0 !important;
        }

        .main-title {
            -webkit-text-stroke: 1px $nero;

            @include mq($not-mobile) {
                padding: 0;
            }
        }

        .title-opposite {
            // Rework global title opposite into text stroke
            text-shadow: none !important;
        }

        .content {
            display: flex;
            flex-direction: column;

            @include mq($not-mobile) {
                flex-direction: row;
            }
        }
    }

    .home-club__text-col {
        @include mq($not-mobile-tablet) {
            width: 430px;
        }

        @include mq($mobile-max) {
            padding-bottom: 50px;
        }

        .home-club__timetable {
            position: relative;

            &--date {
                @include h5;

                color: $safety-orange;
                margin-bottom: 22px;
                cursor: pointer;
                user-select: none; // Prevent highlight on multiple click

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include iconfont-styles;

                    content: svg(chevron-down);
                    font-size: 20px;
                    font-weight: bold;
                    color: $black;
                }

                &.hide-timetable {
                    + .toggle-content {
                        display: none;
                    }

                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }

            .toggle-content {
                max-height: 400px;
                overflow-y: scroll;
            }
        }

        .home-club__details {
            margin-bottom: 0;

            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 2px 2px 2px 0;

                @include mq($tablet-max) {
                    display: block;
                }

                li {
                    white-space: nowrap;
                    width: 50%;

                    @include mq($tablet-max) {
                        padding: 0;
                        width: 100%;
                    }
                }
            }

            &--print-schedule::before,
            &--live-gxr::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }

            &--print-schedule {
                font-size: 12px;
                font-family: $heading-pro-treble-extra-bold;
                flex-basis: 50%;
                text-transform: uppercase;
                white-space: nowrap;
                cursor: pointer;

                &::before {
                    width: 16px;
                    height: 16px;
                    background-image: url("../../img/svg/printer.svg");
                    background-size: contain;
                }
            }

            &--live-gxr::before {
                display: none;
            }

            .filter-status__live,
            .filter-status__virtual {
                border: none;
                flex-basis: 50%;
                margin-left: auto;
                text-align: left;
                user-select: none; // Prevent highlight on multiple click

                label::before {
                    display: none;
                }

                &:active {
                    outline: auto;
                }
            }
        }
    }

    .home-club__image-col {
        flex-grow: 1;
        padding-bottom: 40px;

        @include mq($not-mobile) {
            padding-bottom: 20px;
            padding-left: 33px;
        }

        .home-club__image {
            display: block;
            font-size: 0;
            padding: 0;
            margin: 0 9px 9px 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            width: 100%;
            max-width: 750px;

            @include mq($not-mobile) {
                max-width: 100%;
                max-height: 350px;
                margin: 0;
            }

            &::before {
                content: "";
                display: block;
                @include ratio-prop-number(padding-top, 300px 200px);

                width: 100%;
            }
        }

        .home-club__next-class {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            padding: 0 15px;

            @include mq($not-mobile) {
                padding: 0;
            }

            &--title {
                @include h5;

                font-weight: bold;
                text-transform: uppercase;
            }

            &--title-first {
                color: $spray;
            }

            &--description {
                margin-top: 20px;
            }
        }
    }

    .gxr-classes__list {
        height: 100%;

        @include mq($mobile-max) {
            width: 100%;
        }
    }

    .gxr-item {
        border-bottom: solid 1px $silver;

        &:first-child {
            border-top: solid 1px $silver;
        }
    }

    .gxr-item--full {
        &.gxr-item__link::after {
            color: $silver;
            opacity: 0.6;
        }
    }

    .gxr-item__link {
        background-color: $white;
        align-items: center;
        color: $black;
        display: flex;
        height: 55px;
        position: relative;
        padding: 0 4px;

        .gxr-item__hours {
            margin-right: 16px;
            min-width: 100px;
        }

        .gxr-item__title__label {
            @include label;

            position: relative;
            text-transform: uppercase;
            flex-direction: column;
            align-items: flex-start;
            font-weight: bold;

            &.virtual-classes,
            &.live-classes {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 45%;
                    left: -8px;
                    transform: translateY(-50%);
                    width: 3px;
                    height: 13px;
                }
            }

            &.virtual-classes {
                &::before {
                    background-color: $safety-orange;
                }
            }

            &.live-classes {
                &::before {
                    background-color: $green;
                }
            }
        }

        .gxr-item__title__info {
            background-color: $safety-orange;
            margin-left: auto;
            text-transform: uppercase;
            color: $white;
            font-weight: bold;
            width: 68px;
            height: 31px;
            line-height: 32px;
            text-align: center;
        }

        &::after {
            content: svg(arrow-right);
            @include iconfont-styles;

            display: inline-block;
            color: $safety-orange;
            font-size: 19px;
            line-height: 1;
            vertical-align: middle;
            margin-left: auto;
        }
    }
}
