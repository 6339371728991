.line-animation-block {
    position: relative;

    svg {
        .svg-fixed {
            position: fixed;
            top: 0;
        }

        img {
            object-fit: contain;
        }
    }

    path,
    circle {
        opacity: 0;
    }
}
