// Node_modules tingle.js:

@use "tingle.js/src/tingle.css";

.modal-wrapper {
    background-color: $modal-overlay;

    .tingle-modal {
        &__close {
            text-align: right;

            @include mq($modal-mobile) {
                width: 1em;
                height: 1em;
            }
        }
    }

    .tingle-modal-box {
        border-radius: 0;

        @include mq($modal-mobile) {
            width: 90%;
        }

        @include mq($not-mobile-tablet) {
            width: 76%;
            max-width: 1280px;
        }

        &__content {
            padding: 2em;
        }
    }
}

.modal-wrapper--video {
    .tingle-modal-box {
        background-color: transparent;
    }

    .tingle-modal-box__content {
        position: relative;
        padding: 56.25% 0 0;
        overflow-y: auto;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
