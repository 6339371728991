.asset-search-block {
    position: relative;
    width: 100%;
    margin-bottom: 44px;
    @include mq($not-mobile) {
        margin-bottom: 30px;
    }

    .input-wrapper {
        div {
            display: flex;
            align-items: center;
        }
    }

    .search-input {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        border-bottom: 1px solid $safety-orange;
        font-size: 16px;
        line-height: 28px;
        color: $black;
        @include input-placeholder($white, 16px);
        @include mq($not-mobile) {
            width: 310px;
        }

        &:focus {
            border-color: $safety-orange;
        }

        @include mq($mobile-max) {
            height: 56px;
        }

        .dark-body & {
            background: $pastel-grey;
        }

        .dark-body .timetable-component & {
            background: $white;
        }
    }

    .search-button {
        display: block;
        width: 48px;
        height: 48px;
        margin-left: -48px;
        padding: 0;
        border: 0;
        border-radius: 50%;
        font-size: 0;
        background-color: transparent;
        cursor: default;

        &::after {
            @include iconfont-styles;

            content: svg(search);
            display: inline-block;
            vertical-align: middle;
            position: relative;
            color: $safety-orange;
            font-size: 24px;
            width: 48px;
        }
    }

    .search-button--active {
        cursor: pointer;

        &:hover {
            background-color: rgba($safety-orange, 0.7);

            &::after {
                color: $white;
            }
        }
    }

    .result-wrapper {
        border: 2px solid $wild-sand;
    }
}

.suggestions-flyout {
    position: absolute;
    top: 60px;
    right: 24px;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 5;

    @include mq($mobile-max) {
        top: 56px;
    }

    .result-wrapper {
        padding: 0;
    }

    .result-content {
        max-height: 270px; // temporary, remove when result limit is implemented
        overflow-y: auto; // temporary, remove when result limit is implemented
        padding: 24px 24px 0;
        box-shadow: 0 4px 4px 0 rgba($black, 0.15);
        border: 1px solid $grey-main-middle;

        @include mq($mobile-tablet-pt) {
            padding: 15px;
        }
    }

    .no-results-container {
        padding: 12px;

        .twa {
            display: none;
        }

        .message {
            margin: 0;
        }

        .tips-title {
            margin: 0 0 12px;
        }

        .tips-list {
            list-style: disc;
            padding-left: 20px;
        }
    }

    .result-location {
        border-bottom: 1px solid $grey-main;
        height: 40px;
        padding: 0 15px;
    }

    .find-link {
        display: flex;
        line-height: 40px;
        color: $blue-2;
        font-size: 14px;

        &::before {
            display: none;
        }

        .icon {
            display: inline-block;
            margin-right: 5px;
        }
    }

    .result-label {
        color: $purple-label;
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .result-content__cities {
        + .result-content__clubs {
            margin-top: 20px;
        }
    }

    .result {
        position: relative;

        + .result {
            margin-top: 10px;
        }

        .icon {
            color: rgba($black, 0.5);
            font-size: 24px;
            left: 0;
            position: absolute;

            @include mq($mobile-tablet-pt) {
                font-size: 18px;
                top: 3px;
            }
        }
    }

    a {
        color: $black;

        &:hover {
            color: $grey-dark;
        }
    }

    .result-anchor {
        display: block;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-transform: capitalize;
        padding: 4px 0 4px 40px;

        @include mq($mobile-tablet-pt) {
            font-size: 16px;
            padding-left: 28px;
        }

        span {
            font-weight: normal;
        }
    }

    .result-content__amount {
        border-top: 1px solid $grey-main-middle;
        color: $purple-label;
        display: block;
        margin: 19px -22px 0;
        padding: 4px 22px;
        width: calc(100% + 44px);

        a {
            font-size: 13px;
            text-transform: none;
        }
    }

    &.timetable {
        left: 2px;
        position: absolute;
        right: 24px;
        top: 58px;

        @include mq($mobile-max) {
            max-height: 30vh;
            overflow: auto;
        }

        .result-wrapper {
            border: none;
            cursor: pointer;
            padding: 12px 20px;
        }
    }

    &.club-search {
        // input height = 72px
        right: 23px;
        top: 77px;

        @include mq($mobile-max) {
            // input height = 48px
            right: 15px;
            top: 52px;
        }

        .professionals-list__search & {
            // input height = 48px
            right: 13px;
            top: 52px;
        }

        .find-professional__tile & {
            // input height = 60px + margin
            top: 50px;

            @include mq($mobile-max) {
                right: 15px;
                top: 40px;
            }

            .suggestions-flyout {
                width: 100%;
                @include mq($not-mobile) {
                    width: 360px;
                }
            }
        }

        .result-content {
            max-height: 270px;
            overflow-y: auto;
            padding: 10px 15px;

            @include mq($mobile-max) {
                padding: 10px;
            }
        }

        .properties {
            font-size: 16px;
            display: flex;
            padding: 7px 0;

            @include mq($tablet-pt) {
                display: block;
            }

            @include mq($mobile-pt) {
                display: block;
            }

            .distance,
            .label-wrapper {
                display: none;
            }
        }

        .city {
            color: $grey-dark;
            margin-right: 10px;
        }
    }
}

.find-link {
    border: 0;
    padding: 0;

    &::before {
        content: "";
        background: url("../../img/svg/location.svg") no-repeat;
        width: 13px;
        height: 20px;
        display: inline-block;
        background-size: contain;
        vertical-align: middle;
        margin-right: 2px;
    }
}

/* Search wrapper */

.search-component {
    position: relative;
    padding: 14px 16px 34px;

    @include mq($not-mobile) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding: 0 3.5%;
    }

    &::before {
        background-color: $pastel-grey;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .error {
        margin-top: 6px;
        font-size: 15px;
        color: $red;
    }
}

.search-component__col {
    @include mq($not-mobile) {
        width: 46%;
    }
}

.search-component__title {
    margin-top: 2px;
    margin-bottom: 0;
    font-family: $impact;
    font-size: 32px;
    line-height: 36px;
    text-transform: uppercase;

    @include mq($not-mobile) {
        font-size: 56px;
        line-height: 56px;
    }
}

.search-component__findlocation {
    &::before {
        @include iconfont-styles;

        content: svg(location-arrow);
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-right: 4px;
        font-size: 14px;
    }
}

.search-component-figure {
    display: none;

    @include mq($not-mobile) {
        display: block;
        align-self: normal;
        margin: -16px 0 0;
        font-size: 0;
    }
}

.search-component-figure__img {
    max-width: 100%;
}

.search-component__search {
    margin-top: 8px;
    margin-bottom: 8px;

    @include mq($not-mobile) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .search-input {
        @include mq($mobile-max) {
            height: 48px;
        }
    }

    .search-button {
        @include mq($mobile-max) {
            width: 32px;
            height: 32px;
            margin-left: -16px;

            &::after {
                width: 32px;
                font-size: 20px;
            }
        }
    }
}
