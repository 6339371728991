@import "emoji-map";

// preview section
.emojimap {
    max-width: $max-width;
    margin: auto;
    @include global-padding;

    h2 {
        font-family: $impact;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 36px;
        margin: 0 0 20px;
    }

    .twa-2x {
        margin: 0 0.2em 0.2em;
    }
}

.twa {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin: 0 0.05em 0 0.1em;
    vertical-align: -0.1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em 1em;
}

$size-map: (
    "lg": 1.33,
    "2x": 2,
    "3x": 3,
    "4x": 4,
    "5x": 5
);

@each $name, $size in $size-map {
    .twa-#{$name} {
        height: 1em * $size;
        width: 1em * $size;
        margin: 0 0.05em * $size 0 0.1em * $size;
        vertical-align: -0.1em * $size;
        background-size: 1em * $size 1em * $size;
    }
}

// lets keep this map commented out but available for now
// It creates a lot of css, if we can remove it lets also remove _emoji-map.scss then

// @each $name, $code in $emoji-map {
//     .twa-#{$name} {
//         background-image: url("https://twemoji.maxcdn.com/2/svg/#{$code}.svg");
//     }
// }

// these were icons ins use in 2019, only two in teh code base: .twa-raised-hands and .twa-party-popper, some others in old assets
.twa-alarm-clock { background-image: url("https://twemoji.maxcdn.com/2/svg/23f0.svg"); }
.twa-smiley { background-image: url("https://twemoji.maxcdn.com/2/svg/1f603.svg"); }
.twa-thumbs-up { background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d.svg"); }
.twa-thumbs-down-sign-type-1-2 { background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3fb.svg"); }
.twa-gift { background-image: url("https://twemoji.maxcdn.com/2/svg/1f381.svg"); }
.twa-raised-hands { background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c.svg"); }
.twa-mobile-phone { background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f1.svg"); }
.twa-woman { background-image: url("https://twemoji.maxcdn.com/2/svg/1f469.svg"); }
.twa-shower { background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bf.svg"); }
.twa-point-down { background-image: url("https://twemoji.maxcdn.com/2/svg/1f447.svg"); }
.twa-point-right { background-image: url("https://twemoji.maxcdn.com/2/svg/1f449.svg"); }
.twa-ok-hand { background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c.svg"); }
.twa-flexed-biceps { background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa.svg"); }
.twa-party-popper { background-image: url("https://twemoji.maxcdn.com/2/svg/1f389.svg"); }
.twa-star-eyes { background-image: url("https://twemoji.maxcdn.com/2/svg/1f929.svg"); }
.twa-house { background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e0.svg"); }
.twa-weight-lifter { background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb.svg"); }
.twa-face-screaming-in-fear { background-image: url("https://twemoji.maxcdn.com/2/svg/1f631.svg"); }
.twa-information-desk-person-type-1-2 { background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3fb.svg"); }
.twa-happy-person-raising-one-hand-type-4 { background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3fd.svg"); }
