.generic-campaign {
    @include mq($tablet-max) {
        padding: 0 15px;
    }
}

.generic-campaign__title {
    font-family: $heading-pro-treble-extra-bold;

    &::after {
        background-color: $grey-main-middle;
        content: "";
        display: block;
        margin-top: 42px;
        width: 87%;
        height: 1px;

        @include mq($until-dt-med) {
            width: 95%;
        }

        @include mq($tablet-max) {
            margin-top: 33px;
            width: 100%;
        }
    }
}

.generic-campaign-popup {
    background: $white;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 128px 0 110px 72px;
    width: 70%;
    overflow: hidden;

    @include mq($tablet-desktop-xxxl) {
        padding: 80px 0 60px 72px;
        width: 90%;
    }

    @include mq($tablet-max) {
        display: block;
        padding: 20px 50px;
        width: 95%;
    }

    @include mq($mobile-max) {
        padding: 50px 20px;
    }

    &__success-message {
        color: $green;
        @include paragraph-small;

        font-family: $heading-pro-treble-regular;
        font-size: 14px;

        a {
            @include paragraph-small;

            font-family: $heading-pro-treble-regular;
            font-size: 14px;
            text-transform: none;
        }
    }

    &__subscribe {
        display: flex;
        margin-top: 32px;
        margin-bottom: 12px;
        width: 66.5%;

        @include mq($tablet-desktop-xxxl) {
            width: 73.5%;
        }

        @include mq($tablet-max) {
            width: 100%;
        }

        input {
            height: 56px;
            border: 1px solid $grey-main-middle;
            padding: 10px 20px;

            &::placeholder {
                @include body;

                color: $grey-main-middle;
            }
        }
    }

    &__subscribe-btn {
        font-family: $heading-pro-treble-extra-bold;
        font-size: 14px;
        line-height: 16px;
        border: 1px solid $safety-orange;
        text-transform: none;
        padding: 0 30px;

        @include mq($tablet-desktop-xxxl) {
            padding: 0 15px;
        }

        @include mq($mobile-max) {
            padding: 0 5px;
        }
    }

    &__checkbox-label {
        @include body;

        font-size: 12px;
    }

    &__checkbox {
        min-height: auto;
        margin-bottom: 3px;

        &:checked {
            + .generic-campaign-popup__checkbox-label,
            + .bf-newclubs-search-block__checkbox-label {
                &::after {
                    position: absolute;
                    left: 2px;
                    top: -1px;
                    @include iconfont-styles;

                    content: svg(check-fat);
                    color: $safety-orange;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 10px;
                }
            }
        }

        .checkbox {
            &::before {
                border: 1px solid $grey-main-middle;
            }
        }

        .form-group {
            border: none;
            background-color: transparent;
        }
    }

    &--overlay {
        background-color: rgb(0 0 0 / 70%);
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        &.show {
            display: block;
        }
    }

    &__title {
        margin-bottom: 12px;
    }

    &__paragraph {
        @include body;

        color: $black;
        margin-bottom: 0;
        width: 70%;

        @include mq($mobile-max) {
            font-size: 14px;
            width: 100%;
        }
    }

    &__content,
    &__content-image {
        flex-basis: 50%;
    }

    &__content {
        span.generic-campaign-validation-error {
            color: $red;
        }

        span.generic-campaign-validation-error-terms {
            color: $yellow;
        }
    }

    &__content-image {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 75%;
        width: 45%;

        @include mq($tablet-max) {
            position: relative;
            top: auto;
            transform: translateY(0);
            width: 100%;
            height: 400px;
        }

        @include mq($mobile-max) {
            height: 100%;
        }

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }

    &__close-icon {
        cursor: pointer;
        position: absolute;
        top: 22px;
        right: 26px;
        z-index: 20;

        &::before {
            content: "";
            background: url("../../img/svg/cross.svg") no-repeat;
            width: 17px;
            height: 17px;
            display: inline-block;
            background-size: contain;
            vertical-align: middle;
        }
    }
}

.new-clubs-validation-error,
.new-clubs-validation-error-terms {
    @include paragraph-small;

    font-size: 13px;
    display: block;
    font-family: $heading-pro-treble-regular;
}

.new-clubs-validation-error {
    color: $red;
    margin-bottom: 3px;
}

.new-clubs-validation-error-terms {
    color: $yellow;
}
