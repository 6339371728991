// Node_modules swiper:

@use "swiper/swiper.min.css";

@import "../05-components/modal";

.article-tile {
    display: flex;
    flex-direction: column;
    @include mq($mobile-max) {
        flex-direction: row;
        padding: 10px 0;
        box-shadow: 0 0 0 0 transparent;
        border-bottom: 1px solid $wild-sand;

        &:first-child {
            padding-top: 0;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .title {
        font-family: $impact;
        text-transform: uppercase;
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 8px;
        @include mq($mobile-max) {
            //font-family: $argumentum;
            font-size: 16px;
            font-weight: bold;
            line-height: 23px;
            text-transform: none;
            margin: 0;
        }
    }

    .image {
        @include mq($mobile-max) {
            width: 33%;
            flex-shrink: 0;

            .image-cutoff {
                display: none;
            }
        }
    }

    .properties {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        @include mq($mobile-max) {
            flex-direction: row;
            padding: 0 0 0 16px;
        }

        .synopsis-wrapper {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            @include mq($mobile-max) {
                justify-content: flex-end;
            }

            .arrow-right {
                display: block;
                padding-right: 24px;
                position: relative;

                &::before {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .synopsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .label-wrapper,
        .synopsis {
            @include mq($mobile-max) {
                display: none;
            }
        }
    }

    // the club and article tile is an <a> tag
    // cater for the DIS in the articles overview and related articles
    .image-wrapper {
        display: none;

        .articles-wrapper:not(.double-tile) &.desktop-normal {
            @include mq($not-mobile) {
                display: block;
            }
        }

        .articles-wrapper:not(.double-tile) &.mobile-normal {
            @include mq($mobile-max) {
                display: block;
                width: 33%;
                flex-shrink: 0;

                .image {
                    width: 100%;
                    flex-shrink: 0;
                }
            }
        }

        .double-tile & {
            &.desktop-large {
                @include mq($not-mobile) {
                    display: block;
                }
            }

            &.mobile-large {
                @include mq($mobile-max) {
                    display: block;
                }
            }
        }
    }
}

.club-tile {
    .properties {
        text-transform: none;

        .city {
            @include label;

            &::before {
                content: "";
                background: url("../../img/svg/location.svg") no-repeat;
                width: 13px;
                height: 20px;
                display: inline-block;
                background-size: contain;
                vertical-align: middle;
                margin-right: 4px;
            }
        }

        .street {
            @include h7;

            margin-bottom: 6px;
            font-family: $heading-pro-treble-extra-bold;
        }

        .title {
            text-transform: none;
        }
    }

    &.half-screen-tile {
        width: 48%;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
        @include mq($mobile-max) {
            width: 100%;
            margin: 0 0 20px;
        }
    }
}

.club-tile,
.article-tile {
    position: relative;
    overflow: visible;

    &.large {
        .image {
            @include mq($not-mobile) {
                // Large Cards: 16:9
                &::before {
                    @include ratio-prop-number(padding-top, 160px 90px);
                }
            }
        }
    }

    .image {
        // Small / medium cards / carousel: 3:2
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: 1;
        height: 244px;

        @include mq($not-mobile) {
            height: 295px;
        }

        &::before {
            content: "";
            display: block;
            @include ratio-prop-number(padding-top, 300px 200px);

            width: 100%;
        }

        &__new-club,
        &__upcoming-club {
            background-color: $safety-orange;
            color: $white;
            position: absolute;
            top: 8px;
            left: -15px;
            font-family: $heading-pro-treble-extra-bold;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            padding: 6px 22px;

            @include mq($tablet-max) {
                left: -8px;
                font-size: 14px;
                line-height: 16px;
                padding: 6px 18px;
            }
        }

        &__upcoming-club {
            background-color: $purple-heart;
        }
    }

    .properties {
        background-color: $white;
        padding: 16px;
        position: relative;
        z-index: 2;
        max-width: 311px;
        min-height: 120px;
        margin-left: auto;
        margin-top: -60px;

        .label-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-top: 20px;
            min-height: 170px;

            .arrow-after {
                margin-top: auto;
                margin-left: auto;
                overflow: visible;
            }
        }

        .labels {
            span {
                background-color: $pastel-grey;
                color: $purple-label;
                padding: 4px;
                margin: 0 4px 4px 0;
                font-size: 10px;
                font-weight: bold;
                line-height: 8px;
                text-transform: uppercase;
                display: inline-block;
            }
        }

        .arrow-right {
            &::before {
                @include iconfont-styles;

                content: svg(arrow-right);
                display: inline-block;
                vertical-align: middle;
                font-size: 18px;
                position: relative;
                color: $orange-1;
            }
        }
    }
}

.video-button {
    position: absolute;
    border: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba($black, 0.2);
    cursor: pointer;
    z-index: 10;

    &:hover {
        bottom: -1px;
        right: 15px;
        box-shadow: 0 2px 2px 0 rgba($black, 0.2);
    }

    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 12px;
        border-color: transparent transparent transparent $safety-orange;
    }
}

// common elements:
.club-tile,
.article-tile,
.content-tile {
    .video-button {
        position: absolute;
        border: 0;
        bottom: 0;
        right: 16px;
        height: 48px;
        width: 48px;
        border-radius: 48px;
        background-color: $white;
        box-shadow: 0 2px 10px 0 rgba($black, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        z-index: 10;

        &:hover {
            bottom: -1px;
            right: 15px;
            box-shadow: 0 2px 2px 0 rgba($black, 0.2);
        }

        &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 12px;
            border-color: transparent transparent transparent $blue-2;
        }
    }

    .arrow-right {
        &::before {
            @include iconfont-styles;

            content: svg(arrow-right);
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            position: relative;
            color: $orange-1;
        }
    }

    .tile-wrap {
        height: 100%;
        position: relative;
    }
}
// related article wrapping:
.related-articles {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    .articles-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin: auto;
        flex-wrap: wrap;
        @include mq($mobile-max) {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &.double-tile {
            @include mq($mobile-max) {
                margin: 0 auto 20px;
            }

            a.article-tile {
                background-color: $white;
                height: auto;
                display: block;
                @include mq($mobile-max) {
                    box-shadow: 1px 1px 0 0 $wild-sand;
                    border: 0;
                    padding: 0;
                    margin: 0 0 16px;
                }
                @include mq($not-mobile) {
                    @include column-tile-calculator(2, 2.75);

                    .image {
                        // Large Cards: 16:9
                        &::before {
                            @include ratio-prop-number(padding-top, 160px 90px);
                        }
                    }
                }

                .image {
                    width: auto;
                    overflow: hidden;

                    .image-cutoff {
                        display: block;
                        left: -1px;
                        right: -1px;
                    }
                }

                .properties {
                    display: block;
                    @include mq($mobile-max) {
                        padding: 16px;

                        .synopsis-wrapper {
                            justify-content: space-between;
                        }
                    }

                    .label-wrapper,
                    .synopsis {
                        display: block;
                    }
                }
            }
        }
    }

    .articles-wrapper:not(.double-tile) a.article-tile {
        background-color: $white;
        height: auto;
        @include mq($not-mobile) {
            @include column-tile-calculator(3, 2.75);
        }
    }
}

/*
    Todo: Delete whole .choice-label css ruleset
    after redesign is completed (these icons are now file upload images)
*/
.choice-label {
    top: 0;
    right: 16px;
    height: 64px;
    width: 64px;
    z-index: 10;
    position: absolute;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    // default fallback english:
    background-image: url("../../img/bestchoice-label-en.png");

    &.label-nl-be,
    &.label-nl-nl {
        background-image: url("../../img/bestchoice-label-nl.png");
    }

    &.label-fr-fr,
    &.label-fr-lu,
    &.label-fr-be {
        background-image: url("../../img/bestchoice-label-fr.png");
    }

    &.label-es-es {
        background-image: url("../../img/bestchoice-label-es.png");
    }
}

// addon and plan tiles wrappers:
.addons-wrapper {
    position: relative;
    margin: auto;
    @include mq($not-mobile) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    // in case there are 4 tiles
    &.even {
        .card__item {
            @include mq($tablet-pt) {
                @include column-tile-calculator(2, 1.5);
            }

            @include mq($tablet-ls) {
                @include column-tile-calculator(4, 1.5);
            }

            @include mq($not-mobile-tablet) {
                @include column-tile-calculator(4, 1.5);
            }
        }
    }

    &.odd {
        .card__item {
            @include mq($tablet-pt) {
                @include column-tile-calculator(2, 1.5);
            }

            @include mq($tablet-ls) {
                @include column-tile-calculator(4, 1.5);
            }

            @include mq($not-mobile-tablet) {
                @include column-tile-calculator(4, 1.5);
            }
        }
    }
}

.card.card--addon .card__item {
    // in case there are 4 tiles
    .addons-wrapper.row & {
        @include mq($tablet-pt) {
            @include column-tile-calculator(2, 2);
        }

        @include mq($tablet-ls) {
            @include column-tile-calculator(4, 2);
        }

        @include mq($not-mobile-tablet) {
            @include column-tile-calculator(4, 2);
        }
    }

    @include mq($not-mobile) {
        @include column-tile-calculator(3, 2);
    }

    @include mq($mobile-max) {
        margin: 20px 0;
    }

    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 5px;

        + .properties-selection-wrapper {
            &::before,
            &::after {
                content: "";
                position: absolute;
                pointer-events: none;
                transition: border-color 0.2s ease-in-out;
            }

            &::before {
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                left: -2px;
                top: -2px;
            }

            &::after {
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                left: -4px;
                top: -4px;
            }

            .properties-label {
                position: relative;
                margin-left: 30px;
                cursor: pointer;

                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left: -30px;
                }

                &::before {
                    border: 2px solid $grey-main;
                    background: $white;
                }
            }
        }

        &:checked + .properties-selection-wrapper {
            &::before {
                border-color: $white;
            }

            &::after {
                border-color: $orange-1;
            }

            .properties-label {
                &::after {
                    @include iconfont-styles;

                    content: svg(check-fat);
                    color: $orange-1;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 10px;
                    left: 1px;
                }
            }
        }
    }

    .properties-label {
        display: block;
    }

    .details-wrapper {
        &.open {
            .details {
                height: auto;
                padding-top: 7px;
                padding-bottom: 7px;
                opacity: 1;
                transform: translateZ(0);
            }

            .details-trigger {
                &::before {
                    content: attr(data-close-label);
                }
            }
        }

        .details {
            font-size: 16px;
            line-height: 24px;
            height: 0;
            opacity: 0;
            overflow: hidden;
            transform: translate3d(0, 20px, 0);
            transition: all 0.3s linear;
        }

        .details-trigger {
            display: inline-block;
            color: #609;
            font-size: 14px;
            text-decoration: underline;

            &::before {
                content: attr(data-open-label);
            }
        }

        .details-trigger-wrapper {
            padding: 8px 0 0;
            cursor: pointer;
        }
    }
}

// plan tiles wrapper:
.plans-wrapper {
    @include mq($not-mobile) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: inherit;
    }

    .promo-small {
        color: $blue-2;
        font-family: $impact;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;
    }

    &.pd-home-promotion {
        .bottom-bar {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .card__registration-fee {
            order: 3;
        }

        .card__price {
            line-height: 28px !important;
        }

        .card__promotion-active {
            .card__price {
                line-height: 36px !important;
            }
        }
    }
}

// content tiles:
.content-tile-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .content-tile {
        height: auto;
        max-width: none;
        @include column-tile-calculator(4, 3.5);
        @include mq($until-dt-min) {
            @include column-tile-calculator(3, 3.5);
        }
        @include mq($mobile-tablet-pt) {
            @include column-tile-calculator(2, 5);
        }
        @include mq($mobile-pt) {
            @include column-tile-calculator(2, 5);
        }
    }
}

// HOME CLUB SERVICES
.content-tile {
    border: 1px solid $grey-main-middle;
    display: block;
    max-width: 200px;
    position: relative;

    a {
        display: block;
        height: 100%;
        position: relative;
    }

    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        transform: translateY(calc(-8.6735% + 1px)); // to position the cutout of the image over the edge of the box
        z-index: 2;

        &::before {
            content: "";
            display: block;
            @include ratio-prop-number(padding-bottom, 248px 200px);

            width: 100%;
        }

        @include mq($tablet-pt) {
            transform: translateY(calc(-8.6735% + 2px));
        }
    }

    .title {
        color: $nero;
        font-size: 16px;
        padding: 16px 10px;
        position: relative;
        text-align: center;
        text-transform: initial;

        @include mq($mobile-pt) {
            padding: 16px 14px 40px;
        }
    }

    .arrow-right {
        bottom: 16px;
        right: 8px;
        position: absolute;
        z-index: 2;
    }
}

/* PERSONAL TRAINER TILE */
.trainer-tile {
    @include mq($not-mobile-tablet) {
        flex-grow: 1;
        height: auto;
        max-width: 304px;
    }
}

.trainer-tile__wrapperlink {
    display: block;
    height: 100%;
    position: relative;
    transition: border-color 0.3s;

    &::after {
        background: $white;
        height: 100%;
        left: 0;
        opacity: 1;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    &:hover {
        color: $black;

        &::before {
            opacity: 1;
        }

        .trainer-tile__arrowright {
            transform: translate3d(4px, 0, 0);
        }
    }
}

.trainer-tile__figure-wrapper {
    position: relative;
}

.trainer-tile__figure {
    margin: 0;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &::before {
        content: "";
        display: block;
        @include ratio-prop-number(padding-top, 300px 300px);

        width: 100%;
    }
}

.trainer-tile__figure__image {
    display: block;
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}

.trainer-tile__cerification-logo {
    top: -30px;
    max-width: 55px;
    position: absolute;
    right: 15px;
    z-index: 2;
}

.trainer-tile__content {
    position: relative;
    z-index: 2;
    background-color: $white;
    padding: 16px 0 16px 20px;
    max-width: 72%;
    min-height: 200px;
    margin-left: auto;
    margin-top: -94px;
    display: flex;
    flex-direction: column;

    .arrow-after {
        float: right;
        color: $nero;
        letter-spacing: 1px;
        margin-top: auto;
        justify-content: flex-end;

        &::after {
            font-size: 22px;
            margin-bottom: 4px;
            margin-left: 2px;
        }
    }

    .meet {
        margin-right: 6px;
    }

    @include mq($mobile-max) {
        max-width: 90%;
        padding: 23px 0 16px 20px;
    }
}

.trainer-tile__name {
    margin: 0;
    @include body;

    font-family: $heading-pro-treble-extra-bold;
    color: $nero;
    text-transform: capitalize;

    @include mq($mobile-max) {
        @include h7;

        padding-bottom: 11px;
    }
}

.trainer-tile__title {
    margin: 0;
    color: $nero;
    font-family: $heading-pro-treble-light;
    font-size: 13px;
    line-height: 24px;
    text-transform: none;
}

.trainer-tile__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 6px;

    @include mq($mobile-max) {
        margin-top: 11px;
    }
}

.trainer-tile__tag {
    padding: 7px 7px 6px;
    background-color: $white;
    border: 1px solid $nero;
    border-radius: 5px;
    font-family: $heading-pro-treble-extra-bold;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
    align-self: baseline;
    white-space: nowrap;

    &.selected {
        background-color: $safety-orange;
        border-color: $safety-orange;
        color: $white;
    }

    &:last-child {
        margin: 0 0 21px;

        @include mq($mobile-max) {
            margin: 0 0 7px;
        }
    }

    @include mq($mobile-max) {
        padding: 7px 8px 6px;
    }
}

.trainer-tile__arrowright {
    appearance: none;
    background-color: transparent;
    border: 0;
    bottom: 8px;
    position: absolute;
    right: 8px;
    transition: transform 0.3s;
}

.trainer-tile__arrowright__icon {
    &::before {
        @include iconfont-styles;

        color: $orange-1;
        content: svg(arrow-right);
        display: block;
        font-size: 30px;
    }
}

// Personal trainer tile in wrapper

.trainer-tiles {
    padding-top: 30px;
    padding-bottom: 50px;
    overflow: hidden;
    @include global-padding;

    @include mq($not-mobile) {
        padding-top: $header-height;
    }

    .main-title {
        font-size: 30px;
        line-height: 36px;

        @include mq($not-mobile-tablet) {
            font-size: 48px;
            line-height: 52px;
        }
    }

    .trainer-tile {
        margin-bottom: 16px;
        background-color: $white;
        min-width: calc(25% - 22px);

        @include mq($not-mobile) {
            &:not(.swiper-slide) {
                max-width: 304px;
                @include column-tile-calculator(4, 2);
            }
        }

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }

        img {
            text-transform: none;
            font-size: 12px;
        }
    }

    @include mq($mobile-max) {
        padding-left: 0;
        padding-right: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.trainer-tiles__title {
    margin-top: 0;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 36px;
    max-width: 70%;
    word-break: break-word;

    @include mq($mobile-max) {
        margin-bottom: 18px;
        max-width: 100%;
        padding: 0 70px 0 16px;
    }
}

.trainer-tiles__container,
.club-group-wrapper .swiper-only-mobile {
    overflow: visible;

    &.swiper-container-horizontal {
        > .swiper-pagination-bullets {
            &.swiper-pagination-bullets-dynamic {
                top: -24px;
                right: 25px;
                bottom: auto;
                left: auto;
                transform: none;
                max-width: 80px;
            }

            .swiper-pagination-bullet {
                vertical-align: middle;
            }

            .swiper-pagination-bullet-active {
                background-color: $orange-1;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.trainer-tiles__wrapper {
    @include mq($not-mobile) {
        &:not(.swiper-wrapper) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.trainer-tiles__container {
    padding: 40px 0 50px;

    &.swiper-container {
        padding: 40px 0;
        overflow: visible;
    }

    @include mq($mobile-max) {
        padding: 0 16px 50px;

        &.swiper-container {
            padding: 0 0 50px 16px;
        }
    }
}

.content-tile-container-services {
    margin: 0 auto;
    display: block;

    @include mq($not-mobile) {
        margin-top: 90px;
    }

    &__tiles {
        display: flex;

        @include mq($mobile-max) {
            flex-wrap: wrap;
        }
    }

    &__description-title {
        font-family: $heading-pro-treble-heavy;
        @include h3;

        margin-bottom: 30px;
        width: 60%;

        @include mq($mobile-max) {
            @include h5;

            margin-bottom: 20px;
            width: 100%;
        }
    }

    &__description-paragraph {
        font-family: $heading-pro-double-regular;
        @include paragraph;

        width: 35%;

        @include mq($mobile-max) {
            @include body;

            margin-bottom: 0;
            width: 100%;
        }
    }

    .content-tile {
        position: relative;
        border: none;
        padding-bottom: 36px;
        margin-top: 28px;

        @include mq($until-dt-med) {
            padding-bottom: 60px;
        }

        @include mq($mobile-max) {
            flex-basis: 49%;
            padding-bottom: 0;
            margin-top: 26px;
        }

        a {
            margin-bottom: 8px;
            height: auto;
        }

        &__title {
            font-family: $heading-pro-treble-heavy;
            @include h4;

            margin-bottom: 10px;

            @include mq($mobile-max) {
                @include h7;

                text-transform: none;
                min-height: 52px;
            }
        }

        &__link {
            @include body;

            position: absolute;
            top: 100%;
            left: 0;
            bottom: 0;
            text-transform: none;

            @include mq($mobile-max) {
                position: relative;
                width: 95%;
            }
        }

        .image {
            background-size: auto;
            width: 55px;
            height: 75px;
            z-index: 1;
        }

        .arrow-right {
            display: inline-block;
            position: relative;
            right: 0;
            bottom: 0;
            transform: rotate(45deg);
        }

        &:nth-of-type(1n+1),
        &:nth-of-type(2n+2) {
            @include mq($mobile-max) {
                margin-right: 0 !important;
            }
        }

        &:nth-of-type(2n+2) {
            @include mq($mobile-max) {
                padding-left: 10px;
            }
        }

        &:nth-of-type(2n+3),
        &:nth-of-type(2n+4) {
            @include mq($mobile-max) {
                margin-top: 0;
            }
        }
    }
}
