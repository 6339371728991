// ***************** VARIABLES ***************** //
// Description:
// General global variables used in the project
// --------------------------------------------------

// ==================================================
// Font Family
// ==================================================

/** font family **/

// when checking figma:
// Weight: 300 == Heading pro treble - Light
// Weight: 500 == Heading pro treble - regular
// Weight: 800 == Heading pro treble - heavy
// Weight: 700 == Heading pro treble - extrabold
// Weight: 500 == Heading pro double - regular

$heading-pro-treble-light: "HeadingProTreble-Light", sans-serif;
$heading-pro-treble-regular: "HeadingProTreble-Regular", sans-serif;
$heading-pro-treble-heavy: "HeadingProTreble-Heavy", sans-serif;
$heading-pro-treble-extra-bold: "HeadingProTreble-ExtraBold", sans-serif;
$heading-pro-double-regular: "HeadingProDouble-Regular", sans-serif;

// only used in some all-in files
$heading-pro-double-light: "HeadingProDouble-Light", sans-serif;
$impact: "ImpactURW", sans-serif; //(deprecated): REPLACE WHEN REDESIGNING THE COMPONENTS
$iconfont: "iconfont";

// ==================================================
// Global Padding
// ==================================================
$padding-xl: 60px;
$padding-l: 20px;
$padding-s: 16px;
$padding-xs: 10px;

// ==================================================
// Witdh
// ==================================================
$max-width: 1920px;
$max-width-smaller: 1440px;
$max-width-smallest: 1280px;
$max-width-centercol: 1088px;
$medium-col: 860px;
$small-col: 750px;
$large-col: 1315px;
$checkout-col: 640px;
$checkout-col-steps: 1215px;

// ==================================================
// Height
// ==================================================
$header-height: 96px;

// ==================================================
// Input / select height
// ==================================================
$height-input: 52px;

// ==================================================
// Colors :
// ==================================================
// Project colors (please don't copy split off again from variables)
// New color names created on http://www.color-blindness.com/color-name-hue/

$acadia: #2b2b2b;
$mine-shaft-darker: #242424;
$nero: #2d2d2d;
$mine-shaft-dark: #3d3d3d;
$mortar: #575757;
$battleship-grey: #595a59;
$anthracite-50: #808080;
$anthracite: #bdbdbd;
$silver: #c5c5c5;
$wild-sand: #ece9e8;
$white-smoke: #efefef;
$chocolate: #c65824;
$safety-orange: #fe7000;
$sandy-brown: #ffac6a;
$supernova: #ffaa30;
$blanched-almond: #ffe7d3;
$royal-purple: #5d3197;
$purple-heart: #6131ad;
$amethyst: #ad8edf;
$spray: #7adec9;
$turquoise: #7bdfca;
$pattens-blue: #dcecf4;
$summer-sky: #34a4dc;

// OLD color names (phase out)
$white: #fff;
$black: #313136;
$pastel-grey: #ededf7;
$orange-1: $safety-orange;
$orange-2: #ff8d35;
$pastel-orange: #ffe7d3;
$purple-label: #747490;
$purple-0: #d2a8ff;
$purple-1: #7322c9;
$purple-2: #adadd0;

// click states footer
$grey-purple: #9797b7;
$grey-main-dark: #9b9b9b;
$boulder-grey: #767676;
$grey-darker: #747490;
$grey-dark: #6e6e6e;
$grey-main-middle: #d8d8d8;
$silver-1: #d6d6d6;
$grey-main: #d5d5d5;
$grey-soft: #ccc;
$grey-medium-light: #e1e1e1;
$grey-disabled: #eee;
$grey-light: #f5f5fe;
$blue-1: #0ad5dd;
$blue-2: #00a5ff;
$blue-3: #004b4e;
$blue-4: #89e2cf;
$red: #ce0000; // was #fe2800;
$red-soft: #ffecec; // was #ffe4df;
$yellow: #eca71b;
$green: #7dcd19;
$modal-overlay: rgb(49 49 54 / 60%); //$black : $black; with opacity
// club finder:
$map-overlay-gradient: linear-gradient(360deg, rgb(0 0 0 / 16.6%) 43%, transparent 76%);
//  $nero at 50% opacity:
$image-overlay-gradient: linear-gradient(360deg, rgb(45 45 45 / 50%) 0, transparent 30px);
