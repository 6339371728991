// ***************** LAYOUT ***************** //
// Description:
// Generic settings to start writing css
// --------------------------------------------------

// ==================================================
// Generic Layout Elements
// ==================================================

html {
    box-sizing: border-box;
    height: 100%;
    // Font variant
    font-variant-ligatures: none;
    // Smoothing
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

* {
    box-sizing: border-box;
}

body {
    -webkit-overflow-scrolling: touch;
    height: 100%;

    &.fixed,
    &.items-is-open,
    &.show-filter.no-scroll {
        position: fixed;
    }

    &.no-scroll {
        overflow-y: hidden;
    }

    &.scroll-lock {
        // locking styles need to be written inline to work
        // here we can overrule styling that interferes with z-index stacking while the modal is open

        // for PD pages, fix stacking that breaks the modals:
        .custom-background {
            z-index: initial;
        }
    }

    /* Apply user-select: none only when dragging */
    &.dragging {
        user-select: none;
    }

    // chat assistant:
    &.cxco-is-mobile {
        max-width: 100%;
    }

    .cxco-avatar {
        width: 40px;
        height: 40px;
        right: 10px;
        bottom: 15px;
    }
}

.translatez {
    transform: translateZ(0);
}

// screen readers only (A11y proof)
// https://pietromingotti.com/hiding-h1-for-seo-homepage
.visually-hidden,
.hide-text,
.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(0 0 99.9% 99.9%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

// dialog
.dialog-container {
    max-width: 520px;
    background-color: $white;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 6%);
    display: none;
    z-index: layer(base) + 13;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    position: fixed;
    @include mq($mobile-max) {
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 100%;
        transform: translate(0, 0);
    }

    &.open {
        display: block;
    }
}

/* loading overlay */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: layer(base) + 2;

    .main-header-container & {
        z-index: layer(base);
    }

    &.hamburger-overlay {
        @include mq($tablet-max) {
            display: block;
        }
    }

    &.search-filter-overlay {
        @include mq($tablet-max) {
            display: block;
            z-index: layer(base) + 10;
        }
    }

    &.dialog-overlay {
        display: block;
        z-index: layer(base) + 12;
    }

    &.white {
        background-color: rgba($white, 0.75);
    }

    &.black {
        background-color: rgb(20 40 75 / 75%);
    }

    &.fixed {
        position: fixed;
    }

    &.loading {
        z-index: layer(loader);
    }
}

// cookiebar
.cookie-dialog-container {
    display: none;
    background: transparent;
    background-color: $white;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    z-index: layer(base) + 13;
    width: 100%;
    max-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    @include mq($mobile-max) {
        left: 0;
        bottom: 0;
    }

    &.open {
        display: block;
    }

    &::before {
        content: "";
        background: $white;
        opacity: 0.95;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: layer(base-negative);
    }

    .dialog-window {
        max-width: $max-width;
        padding-top: 30px;
        padding-bottom: 30px;
        margin: auto;
        @include global-padding;

        @include mq($tablet-max) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .dialog-content {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: fit-content;
            margin: auto;

            .text {
                margin-right: 20px;
                color: $grey-dark;
                font-size: 16px;
                line-height: 24px;
            }

            .btn {
                width: 310px;
                margin-bottom: auto;
                flex-shrink: 0;
                cursor: pointer;
            }
            @include mq($tablet-max) {
                flex-direction: column;
                width: auto;

                .text {
                    margin: 0 auto 20px;
                }

                .btn {
                    margin-bottom: auto;
                    flex-shrink: 1;
                    max-width: 100%;
                }
            }
        }
    }
}

.maxcolumn {
    max-width: $max-width;
    @include global-padding;
}

.main {
    @include mq($tablet-max) {
        padding-bottom: 50px;

        .pt_storefront &,
        .pt_customer-service &,
        .pt_product-search-result &,
        .pt_store-locator & {
            padding-bottom: 0;
        }
    }
}

// Generate classes for spacing based on map in _mixins.scss
@include spacing;

// ===================
// Hide and Show
// ===================
.display-none {
    display: none !important;
}

.display-mobile-only {
    @include mq($not-mobile) {
        display: none !important;
    }
}

.hide-mobile {
    @include mq($mobile-max) {
        display: none !important;
    }
}

.display-mobile-tablet,
.hide-desktop {
    @include mq($not-mobile-tablet) {
        display: none !important;
    }
}

.hide-mobile-tablet {
    @include mq($tablet-max) {
        display: none !important;
    }
}

.ie11-only {
    display: none;
    @include mq($ie11) {
        display: block;
    }
}

// Scroll prevent
.stop-scrolling-on-touch {
    @include mq($tablet-max) {
        position: relative;
        overflow: hidden;
        touch-action: none;
    }

    .main-header {
        z-index: 50 !important;
    }
}

// Stickyfill polyfill

.sticky::before,
.sticky::after {
    content: "";
    display: table;
}

.strike-through-price {
    text-decoration: line-through;
}

//  swipers:
.swiper-initialized {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

// Page Designer wrapper
.page-designer-reference { // clip hides overflow for line-play (horizontal scroll) without destroying sticky positioning inside
    overflow: clip;
}

// Page Designer custom components order
.custom-order {
    .col-12 {
        display: flex;
        flex-direction: column;
    }

    .experience-layouts-accordionsblock,
    .experience-layouts-tableblock,
    .experience-layouts-professionalblock,
    .experience-layouts-allinbenefitsblock,
    .experience-layouts-allinbenefitsdynamicblock,
    .experience-layouts-allinjobblock,
    .experience-layouts-allinmembershipsteps,
    .experience-layouts-benefitslayout,
    .experience-layouts-contentclubtiles,
    .experience-layouts-exploretoolsblock,
    .experience-layouts-form,
    .experience-layouts-jobspillarsblock,
    .experience-layouts-membershipblock,
    .experience-layouts-orangefamilylayout,
    .experience-layouts-relatedarticleblock,
    .experience-layouts-uspblock,
    .experience-layouts-workatlayout,
    .experience-layouts-yourjourneylayout,
    .experience-assets-contenttextimage,
    .experience-assets-contentwrapper,
    .experience-assets-descriptionblock,
    .experience-assets-extralinks,
    .experience-assets-headlinebanner,
    .experience-assets-jobspromobanner,
    .experience-assets-membershipcomparisonarticleblock,
    .experience-assets-membershipcomparisonblock,
    .experience-assets-promobanner,
    .experience-assets-singleimagecomponent,
    .experience-assets-textblock,
    .experience-assets-videoblock,
    .experience-assets-contentpageherobanner,
    .experience-assets-maphero,
    .experience-allinwebsitelayout-allinaccordions,
    .experience-allinwebsitelayout-allinfaq,
    .experience-allinwebsitelayout-allinsmartbike,
    .experience-allinwebsitelayout-allinthreetile,
    .experience-allinwebsitelayout-allintrainersblock,
    .experience-allinwebsitelayout-allinworkouts,
    .experience-allinwebsiteassets-allin360bike,
    .experience-allinwebsiteassets-allin3tiles,
    .experience-allinwebsiteassets-allinappstorelinksandimg,
    .experience-allinwebsiteassets-allinclubfacilities,
    .experience-allinwebsiteassets-allinclubmap,
    .experience-allinwebsiteassets-allinfreegym,
    .experience-allinwebsiteassets-allintextimagebanner,
    .experience-homepageredesignassets-layouts-background,
    .experience-homepageredesignassets-assets-hpusptile,
    .experience-homepageredesignassets-layouts-clubfinder,
    .experience-homepageredesignassets-layouts-campaignlayout,
    .experience-homepageredesignassets-assets-imagetext,
    .experience-homepageredesignassets-assets-hpblogtiles,
    .experience-homepageredesignassets-layouts-testimonials,
    .experience-homepageredesignassets-assets-hphighlightedclubs,
    .experience-homepageredesignassets-layouts-cities,
    .experience-campaign-assets-herocampaignbanner,
    .experience-campaign-layouts-socialprooftiles,
    .experience-campaign-layouts-membership {
        order: 100;
    }
}
