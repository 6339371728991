.accordions-general {
    .accordions {
        &__title {
            font-family: $heading-pro-treble-heavy;
            font-size: 28px;
            line-height: 32px;
            text-transform: uppercase;
            margin-bottom: 35px;

            @include mq($tablet-max) {
                border-bottom: 1px solid rgba($black, 0.2);
                font-family: $heading-pro-treble-extra-bold;
                font-size: 20px;
                direction: ltr;
                line-height: 26px;
                padding-left: 0;
                padding-right: 40px;
                padding-bottom: 20px;
                position: relative;
                margin-bottom: 6px;

                &::before {
                    @include iconfont-styles;

                    color: $nero;
                    content: svg(chevron-down);
                    display: inline-block;
                    font-size: 17px;
                    line-height: 17px;
                    position: absolute;
                    right: -2px;
                    top: 7px;
                }
            }
        }

        &__block {
            padding-top: 10px;

            @include mq($tablet-max) {
                direction: ltr;
            }
        }

        &__inner {
            @include mq($tablet-max) {
                direction: rtl;
                padding-left: 32px;

                // Firefox fix
                scrollbar-color: $purple-1 $purple-2;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background-color: $purple-2;
                    border-left: 2px solid $white;
                    border-right: 2px solid $white;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $purple-1;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $purple-1;
                }

                &.mobile-open {
                    height: 427px;
                    overflow-y: auto;

                    .accordions__title {
                        &::before {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .accordion {
        &__item {
            &.open {
                .accordion__icon {
                    transform: rotate(180deg);
                    top: 10px;

                    @include mq($tablet-max) {
                        top: 5px;

                        &::after {
                            opacity: 0;
                        }
                    }
                }

                .accordion__question {
                    @include mq($tablet-max) {
                        margin-bottom: 0;
                    }
                }
            }

            @include mq($tablet-max) {
                border-bottom: 1px solid rgba($black, 0.2);
                margin-bottom: 20px;
            }

            @include mq($not-mobile-tablet) {
                padding-bottom: 14px;
            }
        }

        &__question {
            border-bottom: 1px solid rgba($black, 0.2);
            padding-bottom: 2px;
            margin-bottom: 34px;

            @include mq($tablet-max) {
                border: 0;
                margin-bottom: 0;
                padding-bottom: 17px;
            }

            @include mq($not-mobile-tablet) {
                margin-bottom: 20px;
            }
        }

        &__button {
            font-family: $heading-pro-treble-extra-bold;
            font-size: 20px;
            line-height: 36px;
            background: none;
            border: 0;
            padding: 0;
            letter-spacing: 0.7px;
            width: 100%;
            text-align: left;
            padding-right: 40px;
            position: relative;

            @include mq($tablet-max) {
                font-size: 17px;
                line-height: 28px;
                letter-spacing: 0.4px;
            }
        }

        &__icon {
            line-height: 17px;
            position: absolute;
            right: 0;
            top: 13px;

            &::before {
                @include iconfont-styles;

                color: $purple-1;
                content: svg(chevron-down);
                display: inline-block;
                font-size: 17px;
                line-height: 17px;
            }

            @include mq($tablet-max) {
                width: 14px;
                height: 14px;
                top: 0;

                &::before,
                &::after {
                    content: "";
                    background: $nero;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 14px;
                    height: 2px;
                }

                &::after {
                    transform: rotate(90deg);
                }
            }
        }

        &__info {
            p {
                margin-bottom: 26px;
                width: 90%;

                @include mq($not-mobile-tablet) {
                    margin-bottom: 14px;
                }
            }
        }
    }
}
