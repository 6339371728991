// older global "keyboard disabling" is removed from form.scss and links.sccs

// FOCUS RULES:

:focus {
    outline: 2px dashed $nero;
    outline-offset: 2px;
}

:focus:not(:focus-visible) {
    outline: none;

    /* Remove outline for mouse clicks */
}

// whenever we use a mouse to click on any page, the body gets the class "mouse-navigation",
// we use this to avoid seeing the outline meant for keyboard navigation only
.mouse-navigation :focus,
.mouse-navigation :focus-visible {
    outline: none;
}

// for now, viually hide the new swiper notification (since swiper": "11.2.5",)
.swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
