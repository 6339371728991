// Queries:

// min widths:
$desktop-uhd: "only screen and (min-width: 1921px)";
$desktop-xxl: "only screen and (min-width: 1400px)";
$laptop-st: "only screen and (min-width: 1367px)";
$dt-min: "only screen and (min-width: 1280px)";
$not-mobile-tablet: "only screen and (min-width: 1025px)"; // anything above tablet (overrulng in other direction)
$not-mobile-tablet-pt: "only screen and (min-width:  901px)"; // mobile and tablet portrait devices
$not-mobile: "only screen and (min-width: 768px)"; // anything above mobile
$modal-mobile: "only screen and (min-width: 541px)";

// min/max mixed widths:
$tablet-desktop-xxl: "only screen and (min-width: 1025px) and (max-width: 1400px)";
$tablet-desktop-xxxl: "only screen and (min-width: 1025px) and (max-width: 1680px)";
$modal-tablet-max: "only screen and (min-width: 414px) and (max-width: 1024px)";
$tablet: "only screen and (min-width: 768px) and (max-width: 1024px)";
$tablet-ls: "only screen and (min-width: 901px) and (max-width: 1024px)"; // tablet-ls
$tablet-pt: "only screen and (min-width: 768px) and (max-width: 900px)"; // tablet-pt

// max widths:
$until-dt-max: "only screen and (max-width: 1599px)"; // until-dt-max
$until-dt-med: "only screen and (max-width: 1366px)"; // until-dt-med
$until-dt-min: "only screen and (max-width: 1279px)"; // until-dt-min
$tablet-max: "only screen and (max-width: 1024px)"; // tablet and smaller
$mobile-tablet-pt: "only screen and (max-width: 900px)"; // mobile-tablet-pt
$mobile-max: "only screen and (max-width: 767px)"; // below mobile
$mobile-pt: "only screen and (max-width: 415px)"; // mobile portrait (biggest iphone)
$mobile-pt-l: "only screen and (max-width: 550px)";
$mobile-xs: "only screen and (max-width: 359px)"; // extra small mobile device

$print: "print";
$ie11: "screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";
$firefox: "screen and (min--moz-device-pixel-ratio: 0)";
$safari: "not all and (min-resolution: 0.001dpcm)";
$hover: "(hover: hover) and (pointer: fine)"; // android needs the pointer cause it supports hover

// * Media Queries */

@mixin mq($values: "") {
    @if $values != "" {
        @each $val in $values {
            @media #{$val} {
                @content;
            }
        }
    }
}

//* Generated combined Media Queries in propper loading order: */
// the combiner uses these as place holder for write out order at the bottom of final css
// Ideally we phase this out if we can, it would be best in current day to just rely on writing order

@include mq($not-mobile) { /*! "$not-mobile" */ }
@include mq($not-mobile-tablet-pt) { /*! "$not-mobile-tablet" */ }
@include mq($not-mobile-tablet) { /*! "$not-mobile-tablet" */ }
@include mq($until-dt-max) { /*! "until-dt-max" */ }
@include mq($until-dt-med) { /*! "until-dt-med" */ }
@include mq($until-dt-min) { /*! "until-dt-min" */ }
@include mq($dt-min) { /*! "dt-min" */ }
@include mq($tablet-max) { /*! "tablet-max" */ }
@include mq($tablet-ls) { /*! "tablet-ls" */ }
@include mq($mobile-tablet-pt) { /*! "mobile-tablet-pt" */ }
@include mq($tablet-pt) { /*! "tablet-pt" */ }
@include mq($mobile-max) { /*! "$mobile-max" */ }
@include mq($mobile-pt) { /*! "$mobile-pt" */ }
