/* =============================================================================
    Links
   ========================================================================== */

/** Styleguide links **/
a {
    color: $nero;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;

    /* Links with chevron */
    &.chevron-before {
        font-size: 16px;
        line-height: 28px;
        text-transform: none;

        &::before {
            content: svg(chevron-right);
            @include iconfont-styles;

            display: inline-block;
            font-size: 0.85em;
            line-height: 1;
            margin-right: 5px;
            transform: translate3d(0, 2px, 0);
        }
    }
}

.arrow-after {
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    line-height: 28px;
    overflow: hidden;
    position: relative;
    text-transform: none;

    &::before {
        background-color: $safety-orange;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: -100%;
        opacity: 0;
        position: absolute;
        transition: all 300ms;
        width: 100%;
    }

    &::after {
        content: svg(arrow-right);
        @include iconfont-styles;

        color: $safety-orange;
        display: inline-block;
        font-size: 0.85em;
        line-height: 1;
        margin-left: 5px;
        transform: rotate(45deg);
        vertical-align: middle;
    }

    &:hover {
        &::before {
            left: 0;
            opacity: 1;
        }
    }
}
// Modifier variants
.arrow-after--orange {
    &::after {
        color: $safety-orange;
    }
}

.arrow-after--black {
    &::after {
        color: $nero;
    }
}

.arrow-after--white {
    &::after {
        color: $white;
    }
}

.read-more {
    color: $purple-heart;
    font-size: 16px;
    line-height: 28px;
    text-decoration: underline;
    text-transform: none;
}
