@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.loading {
    position: relative;
}

.loader {
    background-color: rgba($white, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    line-height: 0;
    z-index: layer(loader);

    svg {
        max-width: 160px;
        animation: flickerAnimation 1s infinite;
        position: absolute;
    }
}

body > .loader {
    position: fixed;
}
