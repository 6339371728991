@mixin body {
    font-family: $heading-pro-double-regular;
    font-size: 16px;
    line-height: 28px;
}

@mixin paragraph {
    //body big in figma
    font-size: 20px;
    line-height: 32px;
}

@mixin paragraph-small {
    //small text in figma
    font-size: 16px;
    line-height: 28px;
}

@mixin label {
    font-size: 13px;
    line-height: 28px;
}

@mixin h1 {
    font-size: 72px;
    line-height: 74px;
}

@mixin h2 {
    font-size: 56px;
    line-height: 58px;
}

@mixin h3 {
    font-size: 48px;
    line-height: 50px;
}

@mixin h4 {
    font-size: 32px;
    line-height: 36px;
}

@mixin h5 {
    font-size: 24px;
    line-height: 28px;
}

@mixin h6 {
    font-size: 20px;
    line-height: 24px;
}

@mixin h7 {
    font-size: 20px;
    line-height: 26px;
}
