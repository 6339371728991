.dropdown {
    &--type-1 {
        position: relative;

        .dropdown-selected-value {
            display: flex;
            align-items: center;
            padding-right: 45px;
        }

        .dropdown-toggle {
            font-size: 14px;
            line-height: 130%;
            display: flex;
            align-items: center;
            cursor: pointer;

            &::after {
                @include iconfont-styles;

                content: svg(chevron-down);
                display: inline-block;
                font-size: 12px;
                color: inherit;
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 0;
                pointer-events: none;
            }
        }

        .dropdown-menu {
            position: absolute;
            display: none;
            flex-direction: column;
            background-color: $white;
            padding: 12px;
            gap: 4px;
            border-radius: 3px;
            width: 100%;
            box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 16%);

            &.show {
                display: flex;
            }

            &.dropup {
                bottom: 42px;
            }
        }

        .dropdown-item__wrapper {
            padding: 8px 8px 8px 4px;

            &.selected,
            &:hover {
                background-color: $wild-sand;
            }
        }

        .dropdown-item {
            font-family: $heading-pro-double-regular;
            font-size: 16px;
            font-weight: 500;
            line-height: 130%;
            text-transform: none;
            display: flex;
            color: $nero;
            padding-left: 8px;
            width: 100%;
        }
    }
}
