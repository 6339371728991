// Component
.find-professional__wrapper {
    padding: 50px 0 110px;
    position: relative;
    width: 100%;

    section {
        .content-page .main-content & {
            margin: 0;
            max-width: 100%;
        }
    }

    .content {
        margin: 165px auto 0;
        max-width: 995px;
        position: relative;
        width: 100%;
        z-index: 2; // to make sure the search flyout doesn't get overlapped by the footer links

        @include mq($tablet-max) {
            margin-top: 100px;
            max-width: 800px;
        }

        @include mq($mobile-max) {
            margin-top: 78px;
            max-width: 100%;
        }
    }

    @include mq($mobile-max) {
        padding: 45px 0 75px;
    }
}

.find-professional__location-text {
    @include body;
}

.find-professional__location-icon {
    &::before {
        content: "";
        background: url("../../img/svg/location.svg") no-repeat;
        width: 13px;
        height: 20px;
        display: inline-block;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px;
    }
}

.find-professional__header {
    width: 70%;

    @include mq($mobile-max) {
        width: 100%;
    }

    .title {
        margin: 0 auto 30px;
        text-align: left;

        @include mq($mobile-max) {
            margin-bottom: 26px;
        }
    }

    .text-content {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 30px;
        text-align: left;

        @include mq($mobile-max) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.find-professional__grid {
    display: flex;
    justify-content: center;

    @include mq($mobile-max) {
        flex-direction: column;
        margin: 0 -16px;
    }

    .find-professional__tile {
        &:first-child {
            .tile-image {
                background-color: $spray;
            }
        }

        &:last-child {
            .tile-image {
                background-color: $safety-orange;
            }
        }
    }
}

.find-professional__tile {
    background-color: $white;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;

    + .find-professional__tile {
        margin-left: 30px;

        @include mq($mobile-max) {
            margin-left: 0;
            margin-bottom: 150px;
            margin-top: 210px;
        }

        @include mq($tablet-pt) {
            margin-left: 0;
        }
    }

    .tile-image {
        position: relative;
        padding-bottom: calc(365 / 415 * 100%);

        @include mq($mobile-tablet-pt) {
            padding-bottom: calc(270 / 290 * 100%);
        }

        img {
            left: 50%;
            position: absolute;
            top: 40%;
            transform: translate(-50%, -50%);
            width: auto;
            z-index: 0;

            @include mq($mobile-tablet-pt) {
                height: 100%;
            }

            @include mq($mobile-max) {
                top: 30%;
            }
        }
    }

    .tile-profession {
        bottom: 32px;
        color: $safety-orange;
        font-size: 48px;
        left: 0;
        line-height: 36px;
        margin: 0 auto;
        max-width: 75%;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(-5deg);
        transform-origin: 50%;

        span {
            background: linear-gradient(-20deg, $yellow 0%, $safety-orange 100%);
            background-clip: text;
            box-decoration-break: clone;
            color: transparent;
            -webkit-text-fill-color: transparent;
        }

        @include mq($tablet-max) {
            bottom: 35px;
        }

        @include mq($mobile-tablet-pt) {
            bottom: 39px;
            font-size: 40px;
            line-height: 30px;
        }

        @include mq($mobile-max) {
            bottom: 37px;
            font-size: 60px;
            line-height: 45px;
        }

        @include mq($mobile-pt) {
            bottom: 42px;
            font-size: 48px;
            line-height: 36px;
        }
    }

    .search-component__search {
        margin-top: 11px;
    }

    .tile-search {
        position: relative;
        padding: 35px 80px 0;
        background-color: $white;
        z-index: 1;

        @include mq($tablet-max) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include mq($mobile-max) {
            position: absolute;
            top: 80.5%;
            left: 50%;
            transform: translateX(-50%);
            padding: 27px 24px 0;
            width: 93%;
        }

        .title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 0;

            @include mq($mobile-max) {
                font-size: 20px;
                margin-bottom: 24px;
            }
        }

        .asset-search-block {
            position: relative;

            @include mq($mobile-max) {
                margin-bottom: 25px;
            }
        }

        .search-input {
            border-bottom: 1px solid $nero;
            height: 35px;
            color: $nero;
            width: 100%;

            @include mq($mobile-max) {
                padding: 0 20px 0 10px;
            }

            @include input-placeholder($nero, 16px);

            &::placeholder {
                @include body;
            }
        }

        .search-button {
            @include mq($mobile-max) {
                margin-left: -30px;
            }

            @include mq($tablet-max) {
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .find-link {
            background-color: $white;
        }

        .error {
            font-size: 15px;
            color: $red;
        }
    }

    @include mq($mobile-max) {
        position: relative;
    }
}

//Page designer Professionals block
.professional-block {
    position: relative;
    max-width: $max-width-smaller;
    margin: auto;
    overflow: visible;
    min-height: 300px;
    @include global-padding;

    a {
        text-transform: none;
    }

    .tile-image {
        &.green {
            background-color: $spray;
        }

        &.orange {
            background-color: $safety-orange;
        }
    }

    .find-professional__grid .find-professional__tile {
        &:first-child,
        &:last-child {
            .tile-image {
                &.green {
                    background-color: $spray;
                }

                &.orange {
                    background-color: $safety-orange;
                }
            }
        }
    }
}

// Lister
.professionals-list {
    .block-wrapper {
        display: flex;
        margin: 0 auto;
        max-width: 1440px;
        padding-left: 60px;
        padding-right: 60px;
        width: 100%;

        @include mq($tablet-max) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @include mq($mobile-max) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    h2.title {
        font-size: 32px;
        line-height: 36px;
        margin-top: 60px;
        text-transform: uppercase;

        @include mq($mobile-max) {
            font-size: 20px;
            line-height: 24px;
            margin: 30px 0 0;
        }
    }
}

.professionals-list__filters {
    background-color: $pastel-grey;
    border-top: 1px solid $grey-main;

    .block-wrapper {
        justify-content: space-between;

        @include mq($mobile-max) {
            flex-direction: column;
        }
    }

    @include mq($mobile-max) {
        padding-bottom: 8px;
    }
}

.professionals-list__search {
    flex-basis: 300px;

    .search-component__col {
        width: auto;
    }

    .asset-search-block {
        .search-input {
            height: 48px;
            padding-left: 15px;

            @include mq($mobile-max) {
                font-size: 16px;
            }
        }

        .search-button {
            height: 32px;
            margin-left: -18px;
            width: 32px;
            cursor: default;

            &::after {
                font-size: 16px;
                width: 32px;
            }
        }

        .search-button--active {
            cursor: pointer;

            @include mq($hover) {
                &:hover {
                    background-color: $purple-heart;
                    color: $white;
                }
            }
        }
    }

    @include mq($mobile-max) {
        flex-basis: 100%;
    }
}

.professionals-list__select {
    display: flex;
    justify-content: flex-end;

    .select-gender__container,
    .select-ambition__container {
        align-items: center;
        display: flex;
        flex-basis: 300px;
        flex-wrap: nowrap;

        label {
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;
            margin-right: 15px;

            @include mq($mobile-tablet-pt) {
                margin-right: 5px;
            }

            @include mq($mobile-max) {
                display: none;
            }
        }

        .select-wrap {
            border: 1px solid $grey-main;
            background-color: $white;
            height: 45px;
            min-width: 225px;
            position: relative;
            width: 100%;

            &::after {
                @include iconfont-styles;

                color: $black;
                content: svg(chevron-down);
                display: inline-block;
                font-size: 14px;
                line-height: 45px;
                position: absolute;
                right: 10px;
                top: 0;
                width: 13px;
                z-index: 0;

                @include mq($mobile-max) {
                    line-height: 32px;
                }
            }

            @include mq($mobile-tablet-pt) {
                min-width: 0;
            }

            @include mq($mobile-max) {
                height: 32px;
            }
        }

        select {
            background-color: transparent;
            border: none;
            box-shadow: none;
            font-size: 16px;
            height: 43px;
            padding: 0 35px 0 10px;
            position: relative;
            z-index: 1;

            @include mq($mobile-max) {
                font-size: 14px;
                height: 30px;
            }
        }

        &.disabled {
            pointer-events: none;

            label {
                color: rgba($black, 0.5);
            }

            .select-wrap {
                border-color: rgba($grey-main, 0.5);

                &::after {
                    opacity: 0.5;
                }
            }

            select {
                color: rgba($black, 0.5);
                cursor: default;
            }
        }

        @include mq($mobile-tablet-pt) {
            margin-left: 20px;
        }

        @include mq($mobile-max) {
            flex-basis: 50%;
            margin-left: 0;
        }
    }

    .select-gender__container {
        @include mq($mobile-max) {
            flex-grow: 1;
        }
    }

    .select-ambition__container {
        margin-left: 40px;

        @include mq($mobile-max) {
            margin-left: 10px;
        }
    }

    @include mq($mobile-max) {
        justify-content: space-between;
    }
}

.professionals-list__wrap {
    .block-wrapper {
        display: block;
    }
}

.professionals-list__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.25%;

    @include mq($mobile-max) {
        margin: 0 0 25px;
    }

    .trainer-tile {
        display: none;
        flex-grow: 0;
        margin: 0 1.25% 30px;
        max-width: none;
        width: 22.5%;

        + .trainer-tile {
            @include mq($mobile-max) {
                margin-top: 10px;
            }
        }

        .hidden {
            display: none;
        }

        @include mq($mobile-tablet-pt) {
            width: 47.5%;
        }

        @include mq($mobile-max) {
            float: none;
            margin: 0;
            padding: 8px 0;
            width: 100%;
        }
    }

    .trainer-tile__wrapperlink {
        &:hover {
            @include mq($mobile-max) {
                box-shadow: none;
            }
        }

        @include mq($mobile-max) {
            border: none;
            display: flex;
            width: 100%;
        }
    }

    .trainer-tile__figure-wrapper {
        @include mq($mobile-max) {
            margin-right: 8px;
        }
    }

    .trainer-tile__figure {
        &::before,
        &::after {
            @include mq($mobile-max) {
                display: none;
            }
        }

        @include mq($mobile-max) {
            overflow: visible;
            max-width: 90px;
            width: 100%;
        }
    }

    .trainer-tile__figure__image {
        @include mq($mobile-max) {
            position: static;
            width: auto;
        }
    }

    .trainer-tile__cerification-logo {
        @include mq($mobile-max) {
            bottom: -20px;
            max-width: 40px;
            right: 0;
        }
    }

    .trainer-tile__content {
        @include mq($until-dt-med) {
            max-width: 85%;
        }

        @include mq($tablet-max) {
            max-width: 72%;
        }

        @include mq($mobile-max) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            padding: 0;
            margin-top: 0;
            width: 100%;
            max-width: 100%;
        }
    }

    .trainer-tile__name {
        color: $black;
        font-size: 18px;
        font-weight: bold;

        @include mq($mobile-max) {
            font-size: 16px;
            line-height: 14px;
        }
    }

    .trainer-tile__title {
        color: $purple-label;
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;

        .icon {
            color: $safety-orange;
            font-size: 12px;
        }

        @include mq($mobile-max) {
            line-height: 16px;
        }
    }

    .trainer-tile__tags {
        margin-top: 27px;
        overflow: hidden;
        width: calc(100% - 27px);

        @include mq($not-mobile) {
            width: calc(100% - 50px);
        }

        &::before {
            background: linear-gradient(to left, $white 0%, rgba($white, 0) 100%);
            bottom: 16px;
            content: "";
            display: none;
            height: 20px;
            position: absolute;
            right: 43px;
            width: 20px;

            @include mq($mobile-max) {
                display: block;
                bottom: 0;
                right: 0;
            }
        }

        @include mq($mobile-max) {
            margin-top: 0;
            width: 100%;
        }
    }

    .trainer-tile__arrowright {
        bottom: 10px;

        @include mq($mobile-max) {
            display: none;
        }
    }

    hgroup + .trainer-tile__arrowright {
        // if no tags are present, align with address
        bottom: 9px;
    }

    .trainer-tile__arrowright__icon {
        &::before {
            font-size: 23px;
        }
    }

    .trainer-tile__tag {
        margin-bottom: 0;
        white-space: nowrap;

        @include mq($not-mobile) {
            white-space: pre-wrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.professionals-list__btn {
    border: 2px solid $black;
    color: $black;
    display: block;
    margin: 0 auto 30px;
    max-width: 260px;
    width: 100%;

    &:hover {
        color: $black;
    }

    @include mq($mobile-max) {
        max-width: 100%;
    }
}
