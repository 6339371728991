.skip-to-main-link {
    font-family: $heading-pro-treble-extra-bold;
    line-height: 100%;
    text-transform: uppercase;
    background-color: $white;
    color: $purple-heart;
    border: 1px solid  $purple-heart;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    height: 36px;
    font-size: 14px;
    position: fixed;
    top: 45px;
    left: 16px;
    display: inline-flex;
    z-index: -1;

    @include mq($not-mobile) {
        top: 50px;
    }

    @include mq($not-mobile-tablet-pt) {
        left: 40px;
    }

    @include mq($dt-min) {
        top: 56px;
        height: 48px;
        font-size: 16px;
    }

    @include mq($desktop-xxl) {
        left: calc(50vw - var(--scrollbar-width) - 670px);
    }

    &:hover {
        color: $amethyst;
        border-color: $amethyst;
    }

    // FOCUS RULES: overruling existing things to start making the main menu support keyboard navigation
    &:focus {
        z-index: 50;

        // !important because it must overrule important in form.scss
        outline: 2px dashed $nero !important;
        outline-offset: 2px;
    }
}
