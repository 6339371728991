// _form.scss

// /* Forms
//	========================================================================== */
// /**
//  * Known limitation: by default, Chrome and Safari on OS X allow very limited
//  * styling of `select`, unless a `border` property is set.
//  */
// /**
//  * 1. Correct color not being inherited.
//  *	Known issue: affects color of disabled elements.
//  * 2. Correct font properties not being inherited.
//  * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
//  */

button,
input,
optgroup,
select,
textarea {
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
}

// /**
//  * Address `overflow` set to `hidden` in IE 8/9/10/11.
//  */

button {
    overflow: visible;
}

// /**
//  * Address inconsistent `text-transform` inheritance for `button` and `select`.
//  * All other form control elements do not inherit `text-transform` values.
//  * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
//  * Correct `select` style inheritance in Firefox.
//  */

button,
select {
    text-transform: none;
}

// /**
//  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//  *	and `video` controls.
//  * 2. Correct inability to style clickable `input` types in iOS.
//  * 3. Improve usability and consistency of cursor style between image-type
//  *	`input` and others.
//  */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    appearance: button; /* 2 */
    cursor: pointer; /* 3 */
}

// /**
//  * Re-set default cursor for disabled elements.
//  */

button[disabled],
html input[disabled],
select[disabled] {
    cursor: not-allowed;
}

// /**
//  * Address Firefox 4+ setting `line-height` on `input` using `!important` in
//  * the UA stylesheet.
//  */

input {
    line-height: normal;
}

// /**
//  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
//  * `font-size` values of the `input`, it causes the cursor style of the
//  * decrement button to change from `default` to `text`.
//  */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

// /**
//  * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
//  * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
//  *	(include `-moz` to future-proof).
//  */

input[type="search"] {
    appearance: textfield; /* 1 */
    box-sizing: content-box;
}

// /**
//  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
//  * Safari (but not Chrome) clips the cancel button when the search input has
//  * padding (and `textfield` appearance).
//  */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    appearance: none;
}

// /**
//  * Define consistent border, margin, and padding.
//  */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    .intro {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        color: $grey-dark;
    }
}

// /**
//  * 1. Correct `color` not being inherited in IE 8/9/10/11.
//  * 2. Remove padding so people aren't caught out if they zero out fieldsets.
//  */

legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
}

// /**
//  * Remove default vertical scrollbar in IE 8/9/10/11.
//  */

textarea {
    overflow: auto;
    width: 100%;
    min-height: 96px;
    line-height: 34px;
}

// /**
//  * Don't inherit the `font-weight` (applied by a rule above).
//  * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
//  */

select::-ms-expand {
    display: none;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea,
select {
    width: 100%;
    height: 52px;
    padding: 10px 0 0 16px;
    border: 1px solid $nero;
    border-radius: 0;
    background-color: $white;
    color: $black;
    font-size: 18px;
    line-height: 22px;
    appearance: none;

    &.error,
    &.is-invalid {
        border-color: $red;
        background-color: $red-soft;
    }

    &::placeholder {
        color: $purple-label;
        opacity: 1;
    }

    &:input-placeholder {
        color: $purple-label;
    }
}

input[type="date"] {
    border-radius: 0;
    background-color: $white;
    box-shadow: 1px 1px 0 0 $grey-main;
    font-size: 18px;
    line-height: 22px;
    height: 52px;
    border: 2px solid $white;
    color: $black;
    padding: 16px;
    width: 600px;
    max-width: 100%;

    &.error,
    &.is-invalid {
        border-color: $red;
        background-color: $red-soft;
    }

    &:placeholder {
        /* Microsoft Edge */
        color: $purple-label;
    }
}

.select-wrap {
    display: inline-block;
    position: relative;

    select {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    &::after {
        @include iconfont-styles;

        content: svg(chevron-down);
        display: inline-block;
        font-size: 12px;
        margin: 0 0 0 3px;
        color: inherit;
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 6px;
        pointer-events: none;
    }
}

.select-wrap--fullwidth {
    display: block;
    width: 100%;

    &::after {
        top: 50%;
        right: 16px;
        transform: translate(0, -50%);
    }

    select {
        padding-top: 0;
    }
}

.invalid-feedback {
    &:empty {
        margin-top: 0;
    }
}

.btn-row {
    .invalid-feedback {
        color: $red;
        margin-top: 8px;

        &:empty {
            margin-top: 0;
        }
    }
}

.invalid-check-feedback {
    display: block;
    color: $red;
    padding-top: 5px;
    font-size: 13px;
}

.input-row {
    margin-bottom: 15px;
    // for all rows, also checkboxes and radios

    &.is-invalid,
    .invalid-feedback,
    .interval-error,
    .invalid-feedback-iban {
        color: $red;
    }

    .sub-text,
    .invalid-feedback,
    .invalid-feedback-iban,
    .interval-error {
        font-size: 13px;
        line-height: 16px;
        margin-top: 8px;

        &:empty {
            margin-top: 0;
        }
    }

    .invalid-feedback-iban {
        display: none;
    }
}

.text-input-row {
    position: relative;
    margin-bottom: 15px;
    color: $purple-label;

    .form-control-label {
        display: inline-block;
        margin-bottom: 5px;
        letter-spacing: 0;
        white-space: nowrap;
        left: 19px;
        position: absolute;
        top: 16px;
        transition: all 0.25s ease-in-out;
        z-index: 1;
        line-height: normal;
        color: inherit;
        font-size: 18px;
        @include mq($mobile-max) {
            font-size: 16px;
        }

        &.label-safari-date {
            display: none;

            @supports (-webkit-touch-callout: none) {
                display: block;
            }
        }

        &:not(&.tooltp) {
            pointer-events: none;
        }
    }

    .form-group {
        &.required {
            .form-control-label {
                .optional {
                    display: none;
                }
            }
        }

        &.iban-input {
            .form-control {
                &.is-invalid {
                    font-size: 18px;
                }
            }
        }
    }

    &.focus .form-control-label {
        font-size: 12px;
        top: 10px;
    }

    .optional {
        font-weight: 400;
    }
}

.flex-row {
    display: flex;

    > div {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

    .col-50 {
        width: calc(50% - 10px);
    }

    .radio-row {
        margin-bottom: 0;
    }
}

.flex-row-desktop {
    @include mq($not-mobile) {
        display: flex;

        > div {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        .col-50 {
            width: calc(50% - 10px);
        }

        .col-30 {
            width: calc(33.33% - 10px);
        }
    }
}

// radios and checks:
.checkbox-row,
.radio-row {
    position: relative;
    z-index: 2;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 22px;
    min-height: 56px;
    color: inherit;

    &.error,
    .is-invalid & {
        border-color: $red;
        background-color: $red-soft;
    }

    &.selected {
        border-color: $orange-1;
    }

    .form-check-label {
        cursor: pointer;
        display: block;
        padding: 16px;
    }

    .form-group {
        width: 100%;

        .form-control-label {
            cursor: pointer;
            flex-shrink: 1;
            width: calc(100% - 25px);
        }
    }
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;

    + .row-selector {
        position: absolute;
        z-index: -1;
        display: block;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border: 2px solid transparent;
    }

    &:checked + .row-selector {
        border-color: $orange-1;
    }
}

.checkbox-row {
    .form-group {
        background-color: $white;
        border: 2px solid $white;
    }

    .checkbox {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        min-width: 20px;
        width: 20px;
        height: 20px;
        margin: 1px 10px 0 0;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &::before {
            position: relative;
            border: 2px solid $nero;
            background: $white;
            top: 0;
            left: 0;
        }
    }

    input[type="checkbox"] {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;

        &:checked ~ .form-check-label .checkbox {
            &::after {
                @include iconfont-styles;

                content: svg(check-fat);
                color: $orange-1;
                display: inline-block;
                font-size: 11px;
                top: -1px;
                left: 3px;
            }
        }
    }
}

.radio-row {
    background-color: $white;
    border: 2px solid $white;

    .radio {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin: -3px 5px 0 0;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
        }

        &::before {
            position: relative;
            border: 2px solid $nero;
            background: $white;
            border-radius: 16px;
        }

        + .label {
            display: inline-block;
        }
    }

    input[type="radio"] {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;

        ~ .form-check-label {
            .label-row {
                display: flex;
                align-items: center;
                min-height: 30px;
            }

            .price {
                flex-grow: 1;
                text-align: right;
                display: flex;
                justify-content: flex-end;
            }
        }

        &:checked ~ .form-check-label {
            .radio {
                &::after {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    top: 4px;
                    left: 4px;
                    background-color: $orange-1;
                }
            }

            .description {
                display: block;
                position: relative;
                margin: 5px 0 20px 25px;
                font-size: 16px;
                @include mq($mobile-max) {
                    font-size: 16px;
                }

                .price {
                    font-weight: bold;
                    position: absolute;
                    right: 0;
                    bottom: -20px;
                    font-size: 18px;
                    @include mq($mobile-max) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.logged-info {
    font-size: 12px;
}

.embassador {
    max-width: $max-width-smaller;
    margin: 30px auto;
    padding-left: 20px;
    padding-right: 20px;

    .form {
        display: flex;
        flex-wrap: wrap;

        label {
            display: table;
            font-size: 20px;
            font-family: $heading-pro-treble-regular;
            @include mq($tablet) {
                width: 100%;
                table-layout: fixed;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        input,
        textarea {
            border-color: $silver;
        }

        .w-50 {
            width: 100%;
            @include mq($not-mobile) {
                width: calc(50% - 32px);
                margin-right: 32px;
            }

            &.even {
                @include mq($not-mobile) {
                    width: 50%;
                    margin-right: 0;
                }
            }
        }

        .w-100 {
            width: 100%;
        }
    }

    .form__heading {
        width: 100%;
        margin-bottom: 35px;
        font-family: $heading-pro-treble-extra-bold;
        font-size: 24px;
        color: $safety-orange;
        text-transform: capitalize;
    }

    .form__heading--black {
        color: $black;
    }

    .form__description {
        margin-bottom: 25px;
        @include mq($not-mobile) {
            margin-bottom: 35px;
        }
    }

    .form__group {
        width: 100%;
        margin-bottom: 25px;
        @include mq($not-mobile) {
            margin-bottom: 38px;
        }
    }

    .form__group--submit {
        width: 100%;
    }

    .submit-success-wrap,
    .submit-error-wrap {
        font-size: 23px;
        width: 100%;
        font-weight: bold;
        padding: 30px 0;
        display: none;

        &.visible {
            display: block;
        }
    }

    .submit-success-wrap {
        color: $purple-1;
    }

    .submit-error-wrap {
        color: $red;

        p {
            font-size: 16px;
            text-align: left;
        }
    }
}

.content-form-wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 35px 15px;

    .content-form {
        padding-top: 20px;
    }

    .form-wrap {
        position: relative;
    }

    .submit-success-wrap,
    .submit-error-wrap {
        font-size: 23px;
        width: 100%;
        font-weight: bold;
        text-align: center;
        padding: 30px 0;
        display: none;

        &.visible {
            display: block;
        }
    }

    .submit-success-wrap {
        color: $purple-1;
    }

    .submit-error-wrap {
        color: $red;

        p {
            font-size: 16px;
            text-align: left;
        }
    }

    .form-group {
        align-items: center;
        display: flex;
    }

    .text-input-row {
        .form-control-label {
            color: $black;
            font-weight: bold;
            position: unset;
            white-space: unset;
            @include mq($modal-mobile) {
                flex: 0 0 20%;
            }
        }
    }

    input[type="date"] {
        width: 100%;
    }

    input[type="checkbox"] {
        order: -1;
        margin-right: 20px;
    }
}
