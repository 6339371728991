.veil {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.spinner {
    width: 20px;
    height: 20px;
    text-align: center;
    animation: sk-rotate 2s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 10px;
    margin-left: 10px;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $white;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}
