@use "sass:string";

// ==================================================
// SVG Icon class generator
// ==================================================
// For adding icons with HTML
// Generates ready-to-use classes for icons defined in _iconmap.scss
// Needs unquoting function to prevent Sass from escaping the backslash of the hex code

@function unescape($var) {
    @return string.unquote('"#{ $var }"');
}

@each $icon, $hex in $iconmap {
    .icon-#{$icon} {
        &::before {
            content: unescape($hex);
            @include iconfont-styles;
        }
    }
}

// <span class="icon-chevron-left"></span>

// ==================================================
// CSS-only icons
// ==================================================

.icon-plus {
    display: inline-block;
    height: 10px;
    position: relative;
    vertical-align: middle;
    width: 10px;

    &::before,
    &::after {
        background-color: $black;
        bottom: 0;
        content: "";
        display: block;
        height: 10px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
    }

    &::after {
        transform: rotateZ(90deg);
    }

    a & {
        &::before,
        &::after {
            background-color: $blue-2;
        }
    }

    a:hover & {
        &::before,
        &::after {
            background-color: $blue-1;
        }
    }
}
