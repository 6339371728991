@use "sass:math";

$item-height-mob: 375px;
$best-choice-diameter-mobile: 60px;
$best-choice-diameter-desktop: 70px;
$promo-header-height: 45px;
$card-height-mob: 350px;
$card-height-desktop: 480px;
$card--addon-height-mob: 345px;
$card--addon-height-desktop: 366px;
$card-content-offset: 85px;
$card-content-height-desktop: 500px;

.card {
    padding: 10px 0 30px;
    @include mq($not-mobile-tablet) {
        margin-bottom: 140px;
    }

    .card__list {
        display: flex;
        flex-direction: column;
        @include mq($not-mobile-tablet) {
            flex-direction: row;
        }
    }

    .card__item {
        position: relative;
        @include mq($mobile-max) {
            margin-bottom: 20px;
        }
        @include mq($not-mobile-tablet) {
            width: calc(100% / 3);
        }
    }

    .card__inner {
        position: relative;
        display: flex;
        min-height: $item-height-mob;
        transition: all 0.5s linear;
        @include mq($not-mobile-tablet) {
            min-height: $card-height-desktop;
        }

        .card__picture {
            margin: auto;
            display: flex;
            margin-top: -115px;
            max-width: 100vw;
            max-height: 100%;

            @include mq($not-mobile-tablet) {
                margin-top: -70px;
            }
        }
    }

    .card__title {
        color: $spray;
        margin: 0;
    }

    .card__title,
    .card__type {
        @include h6;
        @include mq($not-mobile-tablet) {
            @include h5;
        }
    }

    .card__type {
        margin-top: 0;
        margin-bottom: 15px;
        @include mq($not-mobile-tablet) {
            margin-bottom: 30px;
        }
    }

    .main-title {
        margin: 0 auto 20px;
        @include mq($not-mobile-tablet) {
            margin-bottom: 32px;
        }
    }

    .card__promo-header {
        margin: -30px -30px -15px;
        padding: 8px 30px;
        background-color: $nero;
        color: $white;
        transform: translateY(-45px);
        @include mq($not-mobile-tablet) {
            height: $promo-header-height;
            white-space: nowrap;
        }
    }

    .card__content {
        display: flex;
        flex-direction: column;
        width: 90%;
        min-height: 350px;
        margin: auto auto -85px;
        margin-top: 20px;
        padding: 30px;
        background-color: $white;
        transform: translateY(-$card-content-offset);
        transition: all 0.5s linear;

        @include mq($not-mobile-tablet) {
            position: relative;
            top: -$card-content-offset;
            width: 75%;
            min-height: $card-content-height-desktop;
            margin-bottom: -$card-content-offset;
            margin-right: 0;
            transform: none;
        }

        .discount-label {
            margin-bottom: 0;
        }
    }

    .card__content--promo-header {
        min-height: 395px;

        @include mq($not-mobile-tablet) {
            top: calc(-85px - #{$promo-header-height});
            min-height: 505px;
        }
    }

    .card__info {
        .usp-intro {
            margin: 0 0 12px;

            strong {
                font-family: $heading-pro-treble-extra-bold;
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 10px;
            display: flex;
            align-items: baseline;
            line-height: 16px;

            @include mq($not-mobile-tablet) {
                line-height: 22px;
            }

            &.strikethrough {
                text-decoration: line-through;
                opacity: 0.5;
            }

            &::before {
                content: svg(check-fat);
                @include iconfont-styles;

                display: inline-block;
                margin-right: 5px;
                color: $safety-orange;
                font-size: 0.85em;
                line-height: 1;
                vertical-align: middle;
            }
        }
    }

    .card__info-text-item {
        span {
            color: $safety-orange;
            font-size: 13px;
            margin: 0 5px;
        }
    }

    .card__image {
        height: 100%;
        left: 50%;
        max-height: $item-height-mob;
        object-fit: contain;
        position: absolute;
        top: -50px;
        transform: translateX(-50%);
        width: 100%;

        @include mq($not-mobile) {
            top: -100px;
        }
    }

    .card__best-choice {
        height: $best-choice-diameter-mobile;
        position: absolute;
        right: calc(#{$best-choice-diameter-mobile} / 2);
        top: calc(-#{$best-choice-diameter-mobile} / 2);
        width: $best-choice-diameter-mobile;

        @include mq($not-mobile-tablet) {
            width: $best-choice-diameter-desktop;
            height: $best-choice-diameter-desktop;
        }

        img {
            width: 100%;
        }
    }

    .card__best-choice-text {
        width: 70%;
    }

    .card__link {
        margin-top: 20px;
    }

    // Card type PLAN/ADDON shared styles
    &.card--plan,
    &.card--addon {
        .card__content {
            margin-left: auto;
            @include mq($not-mobile-tablet) {
                position: relative;
                top: -85px;
                width: 95%;
            }

            @include mq($laptop-st) {
                width: 85%;
            }
        }

        .bottom-bar {
            margin-top: auto;

            .card__registration-fee {
                margin-top: 8px;
            }

            .card__registration-fee-text {
                color: rgba($acadia, 0.5);
                font-family: $heading-pro-double-regular;
                font-size: 14px;
                line-height: 18px;
            }

            .card__price-discount {
                color: $purple-heart;
            }

            .card__price-original {
                .card__price-sum,
                .card__price-coma {
                    font-size: 14px;
                    line-height: 16px;
                }

                .card__price-decimal {
                    font-size: 9px;
                    line-height: 11px;
                }

                span {
                    color: $grey-main-dark;
                }
            }
        }

        .card__price {
            @include h4;

            .card__price-sum {
                @include h4;
            }
        }
    }

    // Membership/Plan item hover
    &.card--plan,
    &.card--membership,
    &.card--pillars {
        .card__item {
            &:hover {
                .card__inner {
                    @include mq($not-mobile-tablet) {
                        min-height: $card-height-desktop + 50px;
                        transform: translateY(-50px);
                    }
                }

                .card__content {
                    @include mq($not-mobile-tablet) {
                        transform: translateY(-70px);
                    }
                }
            }
        }
    }

    // Card type plan
    &.card--plan {
        .card__item {
            @include mq($not-mobile) {
                width: 50%;
            }
        }

        .card__content {
            @include mq($mobile-max) {
                margin-bottom: math.div(-$card-content-offset, 2);
            }
        }

        .card__image {
            max-height: $card-height-mob + 15px;
            @include mq($not-mobile) {
                max-height: $card-height-desktop + 15px;
            }
        }

        .card__pricing {
            margin-bottom: 20px;
        }
    }

    // Card type addon
    &.card--addon {
        .card__item {
            position: relative;

            &:hover {
                .card__inner {
                    @include mq($not-mobile-tablet) {
                        min-height: $card--addon-height-desktop + 50px;
                        transform: translateY(-50px);
                    }
                }

                .card__content {
                    @include mq($not-mobile-tablet) {
                        transform: translateY(-70px);
                    }
                }
            }
        }

        .card__title {
            margin-bottom: 7px;
            @include h7;

            color: $nero;
            font-family: $heading-pro-treble-extra-bold;
        }

        .card__inner {
            min-height: $card--addon-height-mob;
            @include mq($not-mobile) {
                min-height: $card--addon-height-desktop;
            }
        }

        .card__content {
            min-height: 250px;

            @include mq($tablet-max) {
                min-height: 300px;
            }

            .card__explanation {
                margin-top: 10px;
                margin-bottom: 15px;
            }
        }

        .card__image {
            max-height: $card--addon-height-mob + 15px;

            @include mq($not-mobile) {
                max-height: $card--addon-height-desktop + 15px;
            }
        }
    }

    &.card--membership {
        font-style: initial;
    }

    &.card--membership,
    &.card--pillars,
    &.card--benefits,
    &.card--benefits-dynamic {
        .card__text {
            max-width: 90vw;
            margin: auto;
        }

        .card__info {
            margin-bottom: 32px;
            margin-top: 16px;

            @include mq($not-mobile-tablet) {
                margin-top: 0;
            }
        }

        .card__list {
            // Spacing fix for component below due card content offset
            margin-bottom: -$card-content-offset;
        }

        // Todo add length on .card--membership and based on .card--${length} apply styles
        // remove script for checking length and equalize page designer with membershipBar.isml
        .card__list--two-items {
            .card__item {
                @include mq($not-mobile-tablet) {
                    width: 50%;
                }
            }
        }

        &.card--three {
            .card__item {
                @include mq($not-mobile) {
                    width: 33%;
                }
            }
        }
    }

    &.card--pillars {
        padding: 50px 0 30px;

        .card__inner {
            min-height: 350px;
            @include mq($not-mobile-tablet) {
                min-height: 590px;
            }
        }

        .card__text {
            margin-bottom: 50px;
            @include mq($not-mobile-tablet) {
                margin-bottom: 100px;
            }
        }

        .card__title {
            color: $black;
            margin-bottom: 20px;
            @include mq($not-mobile-tablet) {
                margin-bottom: 30px;
            }
        }

        .card__description {
            color: $nero;
            margin-bottom: 0;
            max-width: 100%;
            @include mq($not-mobile-tablet) {
                margin-bottom: 0;
                max-width: 50%;
            }
        }

        .card__paragraph {
            color: $nero;
            padding: 0;
        }

        .card__content {
            margin: auto auto -64px;
            min-height: auto;
            padding: 30px 22px;
            transform: translateY(-72px);

            @include mq($not-mobile-tablet) {
                margin: -150px 0 0 auto;
                padding: 30px 32px 30px 28px;
                top: 0;
                transform: none;
                width: 76%;
            }
        }

        .card__list {
            margin-bottom: 0;
        }

        .card__picture {
            @include mq($tablet-max) {
                transform: scale(0.75);
            }
        }
        @include mq($not-mobile-tablet) {
            margin-bottom: 0;
            padding: 80px 0 25px;
        }
    }

    &.card--benefits,
    &.card--benefits-dynamic {
        padding: 50px 0 30px;

        .card__list {
            @include mq($tablet-max) {
                margin-bottom: 0;
            }
        }

        .card__item {
            @include mq($tablet-max) {
                margin-bottom: 14px;
            }
        }

        .card__text {
            @include mq($tablet-max) {
                max-width: 100%;
            }
        }

        .main-title {
            margin-bottom: 20px;

            @include mq($tablet-max) {
                margin-bottom: 25px;
            }
        }

        .card__content {
            min-height: auto;
            top: -170px;

            h3 {
                margin-bottom: 28px;

                @include mq($tablet-max) {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }

            @include mq($tablet-max) {
                margin: -13px 0 -85px auto;
                padding: 30px 24px;
            }

            @include mq($not-mobile-tablet) {
                padding: 30px 30px 60px;
            }
        }

        .card__inner {
            display: block;

            @include mq($tablet-max) {
                min-height: 364px;
            }

            @include mq($not-mobile-tablet) {
                min-height: 590px;
            }
        }

        .card__info {
            p {
                margin-bottom: 16px;

                @include mq($tablet-max) {
                    line-height: 22px;
                }
            }

            @include mq($tablet-max) {
                margin-bottom: 8px;
            }

            @include mq($not-mobile-tablet) {
                margin-bottom: 0;
            }
        }

        .card__link {
            align-items: center;
            display: flex;
            height: auto;
            margin-left: auto;
            margin-top: 0;
            width: fit-content;

            &::after {
                margin-left: 9px;
                margin-bottom: 3px;
                font-size: 20px;
            }

            @include mq($tablet-max) {
                margin-right: -25px;
            }

            @include mq($not-mobile-tablet) {
                position: absolute;
                right: 30px;
                bottom: 0;
            }
        }

        @include mq($tablet-max) {
            padding: 50px 16px 30px;
        }

        @include mq($not-mobile-tablet) {
            margin-bottom: 0;
            padding: 50px 0 25px;
        }
    }

    &.card--benefits-dynamic {
        padding: 0;

        .card__list {
            margin-bottom: 0;

            .card__item {
                &.all-in-experience {
                    .card__title,
                    .card__type {
                        color: $nero;
                    }

                    .card__link {
                        font-family: $heading-pro-treble-extra-bold;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                @include mq($not-mobile-tablet) {
                    width: 32%;
                    margin-right: 2%;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }

            @include mq($not-mobile-tablet) {
                flex-wrap: wrap;
                padding-top: 50px;
            }

            @include mq($tablet-max) {
                padding-top: 30px;
            }
        }

        .card__list--two-items,
        .card__list--four-items {
            .card__item {
                @include mq($not-mobile-tablet) {
                    width: 49%;
                    margin-right: 2%;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .card__content {
                width: 70%;

                @include mq($tablet-max) {
                    width: 90%;
                }
            }
        }

        .card__list--four-items {
            .card__item {
                @include mq($not-mobile-tablet) {
                    margin-bottom: 25px;

                    &:nth-child(odd) {
                        margin-right: 2%;
                    }
                }
            }
        }

        .card__inner {
            z-index: 1;

            @include mq($not-mobile-tablet) {
                min-height: 384px;
                height: 384px;
            }

            @include mq($tablet-max) {
                min-height: 400px;
                height: 400px;
            }
        }

        .card__content {
            top: -114px;
            width: 84%;
            z-index: 2;

            @include mq($tablet-max) {
                width: 90%;
                position: relative;
                top: 0;
            }
        }

        .card__text {
            @include mq($not-mobile-tablet) {
                padding: 80px 0 0;
            }

            @include mq($tablet-max) {
                padding: 30px 0 0;
            }

            .description {
                width: 380px;
                margin-top: 25px;
                font-family: $heading-pro-double-light;
                font-size: 16px;
                line-height: 26px;
                color: $nero;

                @include mq($mobile-max) {
                    margin-top: 65px;
                }
            }
        }

        .main-title {
            margin-bottom: 0;

            &.white {
                color: $white;
            }

            &.all-in-experience {
                width: 749px;
                margin-left: 0;
                line-height: 53px;

                @include mq($mobile-max) {
                    width: 343px;
                    height: 144px;
                    line-height: 36px;
                }
            }

            @include mq($tablet-max) {
                margin-bottom: 0;
            }
        }

        .main-title__part-orange {
            span {
                color: $safety-orange;
            }
        }

        .main-title__part-black {
            span {
                color: $nero;
            }
        }

        .main-title__part-white {
            span {
                color: $white;
            }
        }

        .card__wrap {
            margin: 0 auto;
            max-width: $max-width-smaller;
            padding: 0 64px;
            position: relative;
            width: 100%;
            z-index: 2;

            @include mq($until-dt-med) {
                padding: 0 16px;
            }
        }

        .card__container {
            position: relative;

            &::before {
                background: $safety-orange;
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            &.card__tiles {
                margin-top: -2px;

                @include mq($not-mobile-tablet) {
                    &::before {
                        height: 434px;
                    }
                }

                @include mq($tablet-max) {
                    &::before {
                        height: 582px;
                    }
                }
            }

            &.bg-transparent {
                &::before {
                    display: none;
                }
            }

            &.bg-teal {
                &::before {
                    background: $spray;
                }
            }

            &.bg-gray {
                &::before {
                    background: $white-smoke;
                }
            }
        }

        .benefits__video-item {
            overflow: hidden;
            position: absolute;
            width: 100%;

            @include mq($not-mobile-tablet) {
                height: 384px;
            }

            @include mq($tablet-max) {
                height: 400px;
            }
        }

        .benefits__video-btn {
            background-color: transparent;
            border-radius: unset;
            box-shadow: none;
            height: 100%;
            left: 0;
            top: 0;
            transform: none;
            width: 100%;

            &::before {
                z-index: 2;
            }

            &::after {
                background-color: $white;
                border-radius: 48px;
                border: 0;
                box-shadow: 0 2px 10px 0 rgba($black, 0.2);
                content: "";
                display: block;
                height: 48px;
                left: 50%;
                padding: 40px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 48px;
                z-index: 1;
            }
        }

        @include mq($not-mobile-tablet) {
            padding: 0;
        }

        @include mq($tablet-max) {
            padding: 0;
        }
    }

    &.pd-home-promotion {
        .card__best-choice {
            z-index: 1;

            &.mobile {
                display: block;

                @include mq($not-mobile-tablet) {
                    display: none;
                }
            }

            &.desktop {
                display: none;

                @include mq($not-mobile-tablet) {
                    display: block;
                }
            }
        }

        .card__link-label {
            display: block;
            color: $nero;
            font-family: $heading-pro-double-regular;
            font-size: 14px;
            line-height: 18px;
            opacity: 0.75;
            margin-top: 12px;
            text-align: center;

            @include mq($tablet-max) {
                margin-top: 16px;
            }
        }

        .card__discounted-text-content {
            color: $nero;
            font-family: $heading-pro-double-regular;
            font-size: 12px;
            line-height: 18px;
            width: 70%;
            margin: 9px auto 0;
            text-align: center;

            @include mq($tablet-max) {
                width: 75%;
                margin: 13px auto 15px;
            }

            span {
                color: $safety-orange;
            }
        }

        .card__link {
            background-color: $purple-heart;

            @include mq($not-mobile-tablet) {
                margin-top: 32px;
            }
        }

        .card__content {
            @include mq($not-mobile-tablet) {
                top: -58px;
                width: 83.5%;
            }
        }

        .card__type {
            @include mq($not-mobile-tablet) {
                margin-bottom: 36px;
            }
        }

        .card__info {
            border-bottom: 1px solid $wild-sand;
            padding-bottom: 20px;
            margin-bottom: 39px;

            @include mq($not-mobile-tablet) {
                margin-bottom: 42px;
            }

            p {
                @include mq($not-mobile-tablet) {
                    margin-bottom: 14px;
                }
            }
        }

        .card__price {
            color: $nero;
            font-size: 32px;
            font-family: $heading-pro-treble-heavy;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 22px;

            @include mq($tablet-max) {
                align-items: normal;
                justify-content: normal;
                margin: 0 auto;
            }

            .card__price {
                margin-bottom: 10px;

                @include mq($tablet-max) {
                    margin-bottom: 0;
                }
            }
        }

        .card__per-week {
            font-family: $heading-pro-treble-regular;
            font-size: 11px;
            color: $black;
            text-transform: uppercase;

            @include mq($tablet-max) {
                margin-top: 7px;
            }
        }

        .card__price-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .card__price {
                color: $safety-orange;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;

                @include mq($tablet-max) {
                    margin: 0;
                }
            }
        }

        .card__promotion-active {
            margin-bottom: -21px;
            margin-top: -27px;

            .card__price {
                .card__price-sum,
                .card__price-coma,
                .card__price-decimal {
                    color: $safety-orange;
                }
            }
        }

        .card__promotion-active-founding {
            .card__price-container {
                flex-direction: row;
                justify-content: center;
            }

            .card__per-week {
                margin-left: 4px;
            }
        }
    }
}

.card__item--animation {
    @include mq($not-mobile-tablet) {
        &:hover {
            .card__picture {
                svg {
                    transform: scale(1.1);
                }
            }
        }
    }

    .card__picture {
        @include mq($tablet-max) {
            transform: scale(0.7);
        }

        svg {
            @include mq($not-mobile-tablet) {
                transition: transform 0.5s linear;
            }
        }
    }
}

/*
   Decoupled module of pricing
   Usage 1.homepage designer membership tiles
         2.club detail membership plan
         3.club detail membership add-on
         4.subscription add-on
*/
.card__price {
    display: flex;
    margin-top: auto;
    font-family: $heading-pro-treble-heavy;
    flex-wrap: wrap;
    line-height: 100%;
    align-items: baseline;
    justify-content: flex-end;

    .form-check-label & {
        margin-right: 12px;
    }

    .card__price-decimal,
    .card__price-separator,
    .card__per-week {
        line-height: 1;
    }

    .card__price-separator {
        margin-left: 8px;
        margin-right: 4px;
    }

    .card__price-separator,
    .card__per-week {
        font-family: $heading-pro-double-regular;
        line-height: 130%;
        font-size: 12px;
        color: $nero;
    }

    .card__free {
        font-size: 32px;
    }

    .discounted-price {
        color: $safety-orange;
    }
}

.card__description {
    margin-bottom: 50px;
    @include mq($not-mobile) {
        max-width: 50%;
        margin-bottom: 130px;
    }
}
