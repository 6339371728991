// only used for https://jobs.basic-fit.com/ (older header styling) and some parts of the checkout header

.sticky-header-wrapper {
    height: auto;
    position: sticky;
    transition: height 0.3s linear;
    top: 0;
    z-index: layer(header);

    @include mq($not-mobile-tablet) {
        width: 100%;
    }

    .main-header {
        background-color: transparent;
        height: 64px;
        top: 0;
        transition: all 0.3s ease-in-out;
        width: 100%;
        z-index: layer(header) - 1;

        @include mq($not-mobile-tablet) {
            height: 96px;
        }

        &.non-sticky {
            position: relative;
            border-bottom: 1px solid $wild-sand;

            @include mq($tablet-max) {
                border-bottom: 0;
                height: auto;
            }
        }

        &.is-hidden {
            transform: translateY(-64px);

            @include mq($not-mobile-tablet) {
                transform: translateY(-96px);
            }

            body.has-top-promo-banner & {
                transform: translateY(-122px);

                @include mq($not-mobile-tablet) {
                    transform: translateY(-152px);
                }
            }
        }
    }
}

.main-navigation-wrapper {
    display: flex;
    align-items: center;
    margin: auto;
    height: 64px;
    background-color: $white;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);

    @include mq($not-mobile-tablet) {
        height: $header-height;
    }

    .main-navigation-inner {
        display: flex;
        max-width: $max-width-smaller;
        width: 100%;
        margin: auto;

        @include mq($tablet-max) {
            margin-left: 16px;
            margin-right: 16px;
            justify-content: space-between;
        }

        @include mq($tablet-desktop-xxl) {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    .main-navigation-links {
        display: flex;
        align-items: center;
        margin-left: auto;

        ul {
            min-width: 350px;
        }
    }

    .non-sticky & {
        @include mq($tablet-max) {
            display: block;

            .logo-wrapper {
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}

.logo-wrapper {
    display: flex;
    align-items: center;
}

.bf-logo-header {
    width: 105px;
    height: 40px;
    position: relative;
    display: block;
}

.site-menu {
    @include mq($tablet-max) {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background-color: $nero;
        color: $white;
        text-align: center;

        &.visible {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.2s ease-in-out;
        }
    }

    .mobile-tablet-header {
        display: none;
        @include global-padding;
        @include mq($tablet-max) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 64px;
            padding-top: 3px;
            background-color: $white;

            .bf-logo-menu {
                width: 100px;
                height: 30px;
                position: relative;
                display: block;
                background-image: url("../../img/svg/logo-bf-orange.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .close-main-menu {
                display: flex;
                font-size: 0;

                &::after {
                    @include iconfont-styles;

                    content: svg(close);
                    display: block;
                    font-size: 24px;
                    position: relative;
                    margin: 0;
                    color: $black;
                }
            }
        }

        @include mq($mobile-max) {
            height: 64px;
            padding-top: 2px;
        }
    }

    .mobile-tablet-body {
        height: 100%;

        @include mq($mobile-max) {
            padding-top: 50px;
        }

        @include mq($tablet-max) {
            padding-top: 75px;
            overflow: auto;
        }
    }

    .mobile-tablet-extra-menu-items {
        display: none;
        margin: 20px auto 0;

        @include mq($tablet-max) {
            display: block;
        }

        .join {
            min-width: 150px;
            display: block;
            width: fit-content;
            margin: 30px auto;
        }

        .login-link,
        .big-link {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 22px;
            display: block;
        }

        .login-link {
            color: $white;

            + .login-link {
                margin-top: 20px;
            }
        }

        .big-link {
            margin-bottom: 10px;
        }
    }

    .select-wrap {
        &::after {
            top: 35px;

            @include mq($tablet-max) {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .language-selector-tablet-mobile {
        font-size: 18px;
        line-height: 22px;
        color: $white;
        background-color: transparent;
        border: 0;
        margin: 20px auto;
        padding: 0 20px;
        padding-bottom: 4px;
        text-transform: capitalize;
        box-shadow: none;
        height: 40px;

        option {
            //font-family: $argumentum;
            font-size: 16px;
            line-height: 19px;
            color: $black;
            background-color: transparent;
            text-transform: capitalize;
        }
    }

    .mobile-tablet-footer {
        display: none;

        @include mq($tablet-max) {
            position: absolute;
            left: -20px;
            right: 0;
            bottom: 0;
            background-color: $orange-1;
            height: 64px;
            display: block;
            transform: rotate(-3deg);
            transform-origin: top right;
            overflow: hidden;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: transparent;
        justify-content: flex-start;
        height: 100%;
        margin-left: 48px;

        @include mq($tablet-max) {
            margin-left: 0;
        }

        @include mq($until-dt-med) {
            margin-left: 18px;
        }

        li {
            margin: 0 24px 0 0;

            @include mq($not-mobile-tablet) {
                display: flex;
                align-items: center;
            }

            a {
                color: $black;
                font-size: 16px;
                line-height: 22px;
                position: relative;
                text-transform: none;

                @include mq($until-dt-min) {
                    font-size: 16px;
                }

                &.active,
                &:hover {
                    color: $safety-orange;
                }
            }
        }

        @include mq($until-dt-med) {
            li {
                margin: 0 2% 0 0;
            }
        }

        @include mq($tablet-max) {
            justify-content: space-around;
            flex-direction: column;
            height: auto;

            li {
                margin: 10px 0;

                a {
                    color: $white;
                    font-family: $heading-pro-treble-extra-bold;
                    text-transform: uppercase;
                    font-size: 24px;
                    text-align: center;
                }
            }
        }

        @include mq($mobile-max) {
            li {
                margin: 6px 0;

                a {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
}

.secondary-menu {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;

    a {
        color: $black;

        &:hover {
            color: $grey-dark;
        }
    }

    @include mq($tablet-max) {
        .join-login-wrapper {
            order: 1;
        }

        .search-wrapper,
        .seamly-search-placeholder {
            order: 2;
            margin-left: 10px;
        }

        .menu-toggle {
            order: 3;
            margin-left: 17px;
        }
    }

    @include mq($not-mobile) {
        .search-wrapper,
        .conversational-placeholder,
        .menu-toggle {
            margin-left: 15px;
        }
    }

    .search-wrapper {
        .header-search-link {
            display: block;
            position: relative;

            @include mq($not-mobile-tablet) {
                &:hover {
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        bottom: -27px;
                        left: 0;
                        height: 3px;
                        background-color: $grey-main;
                    }
                }
            }
        }

        .iconfont-search {
            &::after {
                @include iconfont-styles;

                content: svg(search);
                display: block;
                font-size: 24px;
                position: relative;
                margin: 0 16px;

                @include mq($not-mobile-tablet) {
                    font-size: 32px;
                    margin: 0;
                }
            }
        }
    }

    .big-link {
        text-transform: uppercase;
        font-family: $heading-pro-double-regular;
        @include paragraph-small;

        cursor: pointer;

        &.login-link {
            &:hover {
                color: $orange-1;
            }
        }

        &.orange {
            padding: 5px 15px;
            background-color: $safety-orange;
            color: $white;

            &:hover {
                color: $sandy-brown;
            }

            @include mq($not-mobile-tablet) {
                white-space: nowrap;
            }
        }
    }

    .dropdown {
        position: relative;
        display: flex;
        align-items: center;
    }

    .login-link {
        padding-right: 10px;
        padding-left: 3px;
        font-size: 16px;
        line-height: 28px;
        color: $grey-main;
        white-space: nowrap;

        ~ .separator {
            @include mq($not-mobile-tablet) {
                margin-right: 18px;
            }
        }
    }

    .join-login-wrapper {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .big-link {
            @include mq($not-mobile-tablet) {
                margin-left: 18px;
                margin-right: 18px;
            }
        }
    }

    .language-wrapper {
        display: flex;
        align-items: center;

        @include mq($not-mobile-tablet) {
            white-space: nowrap;
        }

        .dropdown-toggle {
            color: $grey-main;
            cursor: pointer;

            &:hover {
                color: $grey-dark;
            }
        }

        .dropdown-menu {
            right: -14px;
        }
    }

    .dropdown-toggle {
        font-family: $heading-pro-double-regular;
        font-size: 16px;

        &::after {
            @include iconfont-styles;

            content: svg(chevron-down);
            font-size: 14px;
            position: relative;
            display: inline-block;
            margin-left: 4px;
        }
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        min-width: 120px;
        top: 24px;
        right: 0;
        border-radius: 4px;
        font-size: 14px;
        line-height: 17px;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 6%);
        background-color: $wild-sand;
        border: 1px solid $grey-main;
        z-index: 1;

        &.show {
            display: block;
        }

        a {
            position: relative;
            display: block;
            margin: 14px;
            padding-right: 18px;
            color: $black;
            font-size: 14px;
            text-transform: capitalize;

            + a {
                border-top: 1px solid $grey-main;
                padding-top: 14px;
            }

            &:first-child {
                &::after {
                    @include iconfont-styles;

                    content: svg(chevron-up);
                    font-size: 14px;
                    position: absolute;
                    display: inline-block;
                    right: 0;
                }
            }
        }
    }

    .seamly-search-placeholder {
        .cx-search-box__popper--activated {
            width: calc(100vw - 75px);
            border: 0;
            max-width: none;
            height: 60px;
            top: -15px;

            @include mq($not-mobile-tablet) {
                height: 96px;
                top: -33px;
                width: 500px;
            }

            @include mq($desktop-xxl) {
                width: 770px;
            }

            .cx-search-box__input {
                padding-left: 60px;
                box-shadow: none;
                @include input-placeholder($nero, 16px);
            }

            .cx-search-box__button {
                left: 0;
                width: 64px;
                height: 100%;
                border-right: 1px solid $wild-sand;
                border-left: 1px solid $wild-sand;

                &::after {
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-size: 20px;
                }
            }
        }
    }
}

.welcome {
    color: $spray;
}

.menu-toggle {
    display: block;
    background-color: transparent;
    padding: 0;
    border: 0;

    &::after {
        @include iconfont-styles;

        content: svg(menu);
        display: block;
        font-size: 24px;
        position: relative;
    }
    @include mq($not-mobile-tablet) {
        display: none;
    }
}

// Recruitment site header
.recruitment-top-header {
    background: $white-smoke;

    .header-inner {
        height: 32px;
        padding: 2px 0;
        width: 100%;

        @include mq($not-mobile-tablet) {
            height: 40px;
            padding: 5px 0;
        }
    }

    .header-wrapper {
        display: flex;
        max-width: $max-width-smaller;
        width: 100%;
        margin: auto;
        justify-content: flex-end;

        @include mq($tablet-max) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            justify-content: space-between;
        }

        @include mq($tablet-desktop-xxl) {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    .language-wrapper {
        padding-left: 15px;

        .jobs-flag-icon {
            display: inline-block;
        }

        .dropdown-toggle {
            color: $nero;

            @include mq($tablet-max) {
                font-size: 14px;
                line-height: 24px;

                &::after {
                    font-size: 12px;
                }
            }
        }

        .dropdown-menu {
            z-index: 41;
        }

        // overwrite general style for .hide-mobile-tablet
        @include mq($tablet-max) {
            display: flex !important;
        }
    }

    .main-site-link {
        @include mq($tablet-max) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.recruitment-header {
    height: 96px;

    .main-header {
        height: 96px;

        @include mq($not-mobile-tablet) {
            height: 136px;
        }
    }

    .seamly-search {
        padding-left: 20px;
    }

    .site-menu {
        ul {
            li {
                margin: 0 0 0 24px;

                @include mq($until-dt-med) {
                    margin: 0 0 0 2%;
                }

                @include mq($tablet-max) {
                    margin: 10px 0;
                }

                @include mq($mobile-max) {
                    margin: 6px 0;
                }
            }
        }

        .mobile-tablet-header {
            @include mq($tablet-max) {
                .bf-logo-menu {
                    width: 100px;
                    height: 30px;
                }
            }
        }

        @include mq($tablet-max) {
            top: 32px;
        }
    }

    .bf-logo-header {
        width: 104px;
        height: 38px;
    }

    .mobile-tablet-extra-menu-items {
        .select-wrap {
            display: none;
        }
    }

    @include mq($not-mobile-tablet) {
        height: 136px;
    }
}

body.moving {
    .recruitment-top-header {
        .header-inner {
            height: 0;
            opacity: 0;
            padding: 0;
        }
    }

    .recruitment-header {
        .site-menu {
            @include mq($tablet-max) {
                top: 0;
            }
        }
    }

    &.has-top-promo-banner {
        .recruitment-header {
            .site-menu {
                @include mq($tablet-max) {
                    top: 44px;
                }
            }
        }
    }
}
