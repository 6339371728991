/* =============================================================================
    Buttons
   ========================================================================== */

/** Styleguide buttons **/

.btn {
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: $heading-pro-treble-extra-bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
    padding: 12px 24px;
    height: 48px;
    min-width: 128px;
    max-width: 343px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 300ms;

    @include mq($mobile-pt-l) {
        width: 100%;
        max-width: 550px;
    }

    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        margin-left: -0.5ch;
    }

    &.disabled,
    &:disabled,
    &[disabled="disabled"] {
        background-color: $anthracite;
        border-color: $anthracite;
        cursor: not-allowed;
        pointer-events: none;

        &:hover {
            background-color: $anthracite;
            border-color: $anthracite;
        }
    }

    &.btn--small {
        padding: 8px;
        height: 36px;
    }

    &.btn--full {
        width: 100%;
        max-width: none;
    }

    /** Old project buttons (deprecated): DON'T use in case of creating new components (LORE 19/Dec/23 BF-3226) **/
    &.purple {
        background-color: $purple-1;
        color: $white;

        &:hover,
        &:active {
            background-color: $purple-0;
        }
    }

    &.white {
        background-color: $white;
        color: $purple-1;

        &:hover,
        &:active {
            background-color: $purple-1;
            color: $white;
        }
    }

    &.outlined {
        background-color: $white;
        border: 2px solid $black;
        color: $black;

        &:hover,
        &:active {
            background-color: $safety-orange;
            border-color: $safety-orange;
            color: $white;
        }
    }
}

.btn--primary {
    background-color: $safety-orange;
    color: $white;

    @include mq($desktop-xxl) {
        &:hover {
            background-color: $sandy-brown;
            color: $white;
        }
    }

    &:active {
        background-color: $chocolate;
    }
}

.btn--secondary {
    background-color: $purple-heart;
    color: $white;

    @include mq($desktop-xxl) {
        &:hover {
            background-color: $amethyst;
            color: $white;
        }
    }

    &:active {
        background-color: $royal-purple;
    }
}

.btn--outlined {
    border: 1px solid $purple-heart;
    color: $purple-heart;

    @include mq($hover) {
        &:hover {
            border-color: $amethyst;
            color: $amethyst;
        }
    }
}

.btn--link {
    background-color: transparent;
    border: 0;
    color: $purple-heart;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    text-decoration: underline;
    text-transform: none;

    &:hover {
        text-decoration: none;
    }
}
