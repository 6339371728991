.comparison-table {
    .title {
        font-family: $impact;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;

        @include mq($modal-mobile) {
            font-size: 32px;
            line-height: 32px;
        }
    }

    > p {
        font-size: 16px;
        line-height: 24px;

        @include mq($modal-mobile) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.comparison-table__container {
    display: flex;
    justify-content: space-between;
}

// left column
.comparison-column__description {
    flex-grow: 1;
    font-size: 12px;
    line-height: 13px;
    margin: 42px 5px 0 0;

    @include mq($modal-mobile) {
        font-size: 18px;
        line-height: 19px;
        margin: 69px 20px 0 0;
    }

    li {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: space-between;

        @include mq($modal-mobile) {
            height: 80px;
        }

        + li {
            border-top: 2px solid $pastel-grey;
        }

        span {
            display: block;
        }
    }

    .tooltip {
        cursor: pointer;
        margin-left: 5px;
        position: relative;
        width: 16px;

        @include mq($modal-mobile) {
            width: 24px;
        }

        &::before {
            background: $black;
            border-radius: 50%;
            color: $white;
            content: "i";
            font-family: Georgia, serif;
            font-size: 10px;
            font-style: italic;
            font-weight: bold;
            display: block;
            height: 16px;
            line-height: 17px;
            padding-right: 1px; // fix center alignment
            text-align: center;
            width: 16px;

            @include mq($modal-mobile) {
                font-size: 15px;
                height: 24px;
                line-height: 24px;
                width: 24px;
            }
        }

        &:hover {
            span {
                opacity: 1;
                visibility: visible;
            }
        }

        .tooltip-text {
            background-color: rgba($black, 0.75);
            border-radius: 3px;
            bottom: 25px;
            color: $white;
            display: block;
            font-size: 12px;
            left: 50%;
            line-height: 13px;
            min-width: 20ch;
            opacity: 0;
            padding: 10px;
            position: absolute;
            transition: all 0.2s ease-in-out;
            transform: translateX(-50%);
            visibility: hidden;

            @include mq($modal-mobile) {
                bottom: 40px;
                font-size: 16px;
                line-height: 19px;
                padding: 15px;
            }

            &::after {
                border: 5px solid rgba($black, 0);
                border-top-color: rgba($black, 0.75);
                bottom: 100%;
                content: "";
                height: 0;
                left: 50%;
                margin-left: -5px;
                pointer-events: none;
                position: absolute;
                top: 100%;
                width: 0;

                @include mq($modal-mobile) {
                    border-width: 10px;
                    margin-left: -10px;
                }
            }
        }
    }
}

// club columns
.comparison-column__club {
    border: 2px solid $pastel-grey;
    border-radius: 2px;
    flex: 0 0 55px;
    transition: border-color 0.2s ease-in-out;

    @include mq($modal-mobile) {
        border-width: 4px;
        flex-basis: 100px;
    }

    // only show 3 columns max on mobile
    &:last-of-type {
        @include mq($mobile-max) {
            display: none;
        }
    }

    &:hover {
        border-color: $safety-orange;
        cursor: default;
    }

    + .comparison-column__club {
        margin-left: 4px;

        @include mq($modal-mobile) {
            margin-left: 8px;
        }
    }

    li {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: $impact;
        font-size: 14px;
        justify-content: center;
        height: 60px;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;

        @include mq($modal-mobile) {
            font-size: 20px;
            height: 80px;
        }

        + li {
            border-top: 2px solid $pastel-grey;
        }

        &.comparison-column__image {
            height: 40px;
            padding: 0;

            @include mq($modal-mobile) {
                height: 65px;
            }

            picture {
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: center;
            }

            img {
                height: auto;
                max-height: 90%;
                max-width: 90%;
                width: auto;
            }
        }

        span {
            color: $safety-orange;
            display: block;
            font-size: 10px;
            font-weight: bold;
            line-height: 16px;

            @include mq($modal-mobile) {
                font-size: 14px;
            }
        }
    }

    .icon-check {
        color: $green;
        font-size: 21px;
    }

    .icon-close {
        color: $grey-main-dark;
        font-size: 14px;
    }
}
