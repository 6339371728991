@use "sass:math";

$items: 3;
$gutter: 32px;
$image-height-desktop: 384px;
$text-position-offset: math.div($image-height-desktop, 4);
$item-content-desktop-height: 280px;

.related-article {
    font-style: initial;
    padding: 10px 16px 60px;
    margin: auto;
    @include mq($not-mobile) {
        max-width: 90vw;
    }

    .related-article__inner {
        display: flex;
        flex-direction: column;
        @include mq($not-mobile) {
            flex-direction: row;
        }
    }

    .related-article__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: $max-width-centercol;
        margin: 0 auto 30px;

        .main-title {
            margin-top: 0;
            margin-bottom: 0;
        }

        @include mq($not-mobile) {
            flex-direction: row;
            align-items: flex-end;
            margin-bottom: 60px;
        }
    }

    .related-article__item {
        margin-bottom: -60px;
        @include mq($not-mobile) {
            width: calc((100% / #{$items}) - (#{$gutter} / 2));
            margin-right: $gutter;
            margin-bottom: -$text-position-offset !important;

            &:last-child {
                margin-right: 0;
            }
        }

        &.mobile-layout__row,
        &:not(:first-of-type) {
            display: flex;
            margin-bottom: 30px;
            @include mq($not-mobile) {
                display: block;
            }
            @include mq($mobile-max) {
                .related-article__title {
                    font-size: 14px;
                    line-height: 20px;
                    max-height: 20px * 3;
                    margin-top: 0;
                }

                .related-article__picture {
                    width: 74px;
                    height: 70px;
                }

                .related-article__image {
                    min-height: auto;
                }

                .related-article__text {
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 0;
                    background-color: transparent;
                }

                .related-article__category,
                .related-article__description {
                    display: none;
                }
            }
        }
    }

    .related-article__title {
        @include h6;

        font-family: $heading-pro-treble-extra-bold;
        max-height: 24px * 3;
        margin-top: 20px;
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .related-article__title,
    .related-article__description {
        padding-right: 18px;
    }

    .related-article__text {
        position: relative;
        top: -$text-position-offset;
        width: 90%;
        margin: auto;
        padding-top: 30px;
        padding-left: 30px;
        background-color: $white;
        @include mq($not-mobile) {
            width: 70%;
            height: $item-content-desktop-height;
            margin-right: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .related-article__category {
        width: fit-content;
        padding: 0 10px;
        border-radius: 5px;
        border: 1px solid $nero;
        font-size: 14px;
        font-family: $heading-pro-treble-extra-bold;
        text-transform: uppercase;
        @include mq($not-mobile) {
            font-size: 11px;
        }
    }

    .related-article__description {
        line-height: 28px;
        max-height: calc(28px * 2);
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
    }

    .related-article__link {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        @include mq($not-mobile-tablet) {
            margin-top: auto;
        }
    }

    .related-article__image {
        width: 100%;
        min-height: $image-height-desktop;
        object-fit: cover;
    }
}
