@charset 'UTF-8';

//* Responsive classes called every where of site  */

.show-only-mobile {
    @include mq($not-mobile) {
        display: none !important;
    }
}

.show-only-desktop {
    @include mq($mobile-max) {
        display: none !important;
    }
}
