.fade-in-animation {
    &.hidden {
        opacity: 0;
    }

    &.fade-in-element {
        animation: 1.5s ease-out 0s 1 slideInFromBottom;
    }
}

.fade-down-animation {
    &.hidden {
        opacity: 0;
    }

    &.fade-down-element {
        animation: 1.5s ease-out 0s 1 slideInFromTop;
    }
}

.slide-in-from-top {
    animation: 1.5s ease-out 0s 1 slideInFromTop;
}

.slide-in-from-right {
    &.hidden {
        opacity: 0;
    }

    &.slide-in-element {
        animation: 1.5s ease-out 0s 1 slideInFromRight;
    }
}

@keyframes slideInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
