/* =============================================================================
    Typography
   ========================================================================== */

/** Styleguid typography **/

//Body text
body {
    @include body;

    background-color: $white;
    color: $nero;

    &.body-bold {
        font-weight: bold;
    }

    &.popup-overlay {
        overflow-y: hidden;

        &::before {
            content: "";
            background-color: $black;
            opacity: 0.45;
            width: 100%;
            height: 1000%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 100%;
            z-index: layer(header) + 1;

            @include mq($not-mobile) {
                bottom: auto;
            }
        }
    }
}

.paragraph {
    @include paragraph;
}

.paragraph-small,
p {
    margin-top: 0;
    @include paragraph-small;
}

.paragraph,
.paragraph-small,
p {
    font-family: $heading-pro-double-regular;
}

.label {
    @include label;
}

//Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.label,
.main-title {
    margin-top: 0;
    font-family: $heading-pro-treble-heavy;
    text-transform: uppercase;
}

.main-title {
    font-size: 32px;
    line-height: 36px;

    @include mq($not-mobile-tablet) {
        font-size: 48px;
        line-height: 52px;
    }

    .title-opposite {
        color: $white;
        @include outline-font($nero);
    }

    .title-opposite--gray {
        color: $white-smoke;
        @include outline-font($nero);
    }
}

h1 .h1 {
    @include h1;
}

h2 .h2 {
    @include h2;
}

h3 .h3 {
    @include h3;
}

h4 .h4 {
    @include h4;
}

h5 .h5 {
    @include h5;
}

h6 .h6 {
    @include h6;
}

.h7 {
    font-weight: 500;
}

/** Old project typography (deprecated): DON'T use in case of creating new components **/

strong,
b,
.strong,
.bold {
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: underline;
}

blockquote {
    &::before,
    &::after {
        content: "\201C";
        display: inline-block;
    }

    p {
        display: inline-block;
    }
}

//lists
ul,
ol {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.checkmark-list li {
    line-height: 1.3;
    margin-bottom: 5px;
    padding-left: 24px;
    position: relative;

    &::before {
        border-style: solid;
        border-width: 3px 3px 0 0;
        color: $safety-orange;
        content: "";
        display: inline-block;
        height: 7px;
        left: 0;
        position: absolute;
        top: 4px;
        transform: rotate(135deg);
        width: 14px;
    }
}

address,
.address,
.address-layout {
    font-style: normal;

    .details {
        color: $grey-dark;
        font-size: 16px;

        > div {
            display: flex;
            margin: 0 0 7px;

            span {
                width: 50%;
            }
        }
    }
}

.rich-text {
    p {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        margin: 0 0 12px;
    }

    ul,
    ol {
        margin: revert;
        list-style: revert;
        padding-left: 20px;
    }

    a {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        text-transform: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &.btn {
            text-decoration: none;
            text-transform: uppercase;
            font-size: initial;
        }
    }

    img {
        max-width: 100%;
    }

    table {
        width: 100%;
        margin: 0 0 12px;

        td:last-child {
            text-align: right;
            white-space: nowrap;
        }
    }
}
