.tags-wrap {
    margin-bottom: 35px;
    @include mq($not-mobile) {
        text-align: center;
    }
}

.tags {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 15px;
}

.tags__btn {
    background-color: $white;
    border-color: rgb(221 221 221 / 60%);
    color: $blue-2;
    font-size: 16px;
    padding: 5px 15px;
    transition: 0.3s ease;

    &:hover {
        @include mq($not-mobile-tablet) {
            background-color: $safety-orange;
            color: $white;
            border-color: transparent;
        }
    }

    &.active {
        background-color: $safety-orange;
        color: $white;
        border-color: transparent;
    }
}
